import { getToken } from 'src/redux/selectors';

export function addStock(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + 'brandxelerator/api/stock/addstock',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(values)
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to add stock' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function saveStock(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + 'brandxelerator/api/stock/savestock',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(values)
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to edit stock' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteStock(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + 'brandxelerator/api/stock/deletestock',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: 'Failed to delete stock' });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER + 'brandxelerator/api/stock/findallstock',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'STOCK_FIND_ALLSTOCK',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findMyStock(make) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER + 'brandxelerator/api/stock/findmystock',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'STOCKS_FIND_MYSTOCKS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findJobNumbers(value) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/stock/getjobnumbers?value=' +
        value,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to get job numbers' });
        }
        return resolve(await res.json());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findStockDetails(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/stock/findstockdetails?id=' +
        id,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'STOCKS_FIND_STOCKDETAILS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/stock/findauctionstock',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'STOCKS_FIND_AUCTIONSTOCKS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findDealDoneStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/stock/finddealdonestock',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'STOCKS_FIND_DEALDONESTOCKS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionWonStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/stock/findauctionwonstock',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'STOCKS_FIND_WONSTOCKS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionLostStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/stock/findauctionloststock',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'STOCKS_FIND_LOSTSTOCKS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setEditStock(stock) {
  return dispatch =>
    dispatch({
      type: 'STOCKS_SET_EDITSTOCK',
      data: stock
    });
}

export function setStockDetailsId(id) {
  return dispatch =>
    dispatch({
      type: 'STOCKS_SET_STOCKDETAILSID',
      data: id
    });
}

export function findVariants(model) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/cddata/findvariants?model=' +
        model,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'CDDATA_FIND_VARIANTS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findYears(variant) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/cddata/findyears?variant=' +
        variant,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'CDDATA_FIND_YEARS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}
export function findVinNumbers(mileage) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/cddata/findvinnumbers?mileage=' +
        mileage,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'CDDATA_FIND_YEARS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}
export function acceptOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + 'brandxelerator/api/stock/acceptoffer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to accept offer' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + 'brandxelerator/api/stock/rejectoffer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to reject offer' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function acceptAdminOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/stock/acceptadminoffer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to accept offer' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectAdminOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'brandxelerator/api/stock/rejectadminoffer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to reject offer' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}
