import React from "react";
import {
  SearchOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Link } from "react-router-dom";
import Logo from "src/static/logo2.svg";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Card,
  Checkbox,
  Badge,
} from "antd";
import { ButtonRadius } from "src/components/elements/Button";
import Highlighter from "react-highlight-words";
import Notify from "src/components/meta/Notification";
import styled from "styled-components";
import { regExpLiteral } from "@babel/types";
const { Column, ColumnGroup } = Table;
const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const numeral = require("numeral");
const { TabPane } = Tabs;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #8898aa !important;
    background: #f1f1f1 !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    font-weight: 500;
    color: #525f7f;
    font-size: 15px;
  }
`;

const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
  .ant-select-selection__rendered:after {
    content: ".";
    display: inline !important;
    visibility: visible !important;
    pointer-events: none;

    width: 0;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    padding-left: 10px !important;
    border-radius: 2000px !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none !important;
    border-radius: 2000px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2000px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

class UserFormClass extends React.Component {
  render() {
    return (
      <div
        style={{
          background: "white",
        }}
      >
        {" "}
        <Row
          style={{
            margin: "auto",
            marginTop: "10vh",
            textAlign: "center",
          }}
        >
          <img src={Logo} style={{ margin: "auto", width: "21em" }} />
        </Row>
        <Row style={{}}>
          <Col xs={24} md={24} style={{ marginTop: "2em" }}>
            <h1
              style={{
                lineHeight: "5px",
                fontSize: "30px",
                color: "black",
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "30px",
              }}
            >
              Join Team Eagle Training Today
              <p
                style={{
                  fontFamily: "Open Sans",
                  marginTop: "40px",
                  marginBottom: "10px",
                  color: "black",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Start your journey
              </p>
            </h1>
          </Col>
        </Row>
        <Form layout="vertical" style={{ maxWidth: "40em", margin: "auto" }}>
          <Row style={{ padding: "1em" }}>
            <Col xs={24} sm={24} md={24} style={{ marginBottom: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("name", {
                  rules: [
                    { required: true, message: "Field entry required" },
                    {
                      //pattern: RegExp(
                      //  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                      //),
                      message: "A name and surname is required",
                    },
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.name
                    : "",
                })(
                  <Input
                    placeholder="Name & Surname"
                    style={{
                      boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
                      height: "3.7em",
                      borderRadius: "3em",
                      paddingLeft: "20px",
                      border: "none",
                    }}
                  />
                )}
              </FormItem>
            </Col>

            <Col xs={24} sm={24} md={24} style={{ marginBottom: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("number", {
                  rules: [
                    { required: true, message: "Field entry required" },
                    {
                      pattern: RegExp(/\d/g),
                      message: "A valid number is required",
                    },
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.number
                    : "",
                })(
                  <Input
                    placeholder="Number"
                    maxLength={10}
                    style={{
                      boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
                      height: "3.7em",
                      borderRadius: "3em",
                      paddingLeft: "20px",
                      border: "none",
                    }}
                  />
                )}
              </FormItem>
            </Col>

            <Col xs={24} sm={24} md={24} style={{ marginBottom: "-1em" }}>
              <FormItem>
                {this.props.form.getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      message: "Field entry required",
                    },
                    {
                      type: "email",
                      message: "A valid email is required.",
                    },
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.email
                    : "",
                })(
                  <Input
                    placeholder="Email"
                    style={{
                      boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
                      height: "3.7em",
                      borderRadius: "3em",
                      paddingLeft: "20px",
                      border: "none",
                    }}
                  />
                )}
              </FormItem>
            </Col>

            {/* <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('identityNumber', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.identityNumber
                    : ''
                })(
                  <Input
                    placeholder="ID/Passport Number"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingLeft: '20px',
                      border: 'none'
                    }}
                  />
                )}
              </FormItem>
            </Col> */}
            {/* <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('countryOfBirth', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.countryOfBirth
                    : ''
                })(
                  <Input
                    placeholder="Country of Birth"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingLeft: '20px',
                      border: 'none'
                    }}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('address', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.address
                    : ''
                })(
                  <Input
                    placeholder="Address"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingLeft: '20px',
                      border: 'none'
                    }}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('race', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.race
                    : ''
                })(
                  <Input
                    placeholder="Race"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingLeft: '20px',
                      border: 'none'
                    }}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('gender', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.gender
                    : ''
                })(
                  <Input
                    placeholder="Gender"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingLeft: '20px',
                      border: 'none'
                    }}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('club', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.club
                    : 'Club'
                })(
                  <StyledSelect
                    placeholder="Club"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingTop: '5px',
                      border: 'none',

                      color: 'rgba(0, 0, 0, 0.43)',
                      textAlign: 'left'
                    }}
                  >
                    <Option value="Figure Skating">Figure Skating</Option>
                    <Option value="Synchro Club">Synchro Club</Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('school', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.school
                    : 'Skating School'
                })(
                  <StyledSelect
                    placeholder="Club"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingTop: '5px',
                      border: 'none',

                      color: 'rgba(0, 0, 0, 0.43)',
                      textAlign: 'left'
                    }}
                  >
                    <Option value="Evolution on Ice">Evolution on Ice</Option>
                    <Option value="Flying Blades">Flying Blades</Option>
                    <Option value="SA School of Skating">
                      SA School of Skating
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('coach', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.coach
                    : 'Coach'
                })(
                  <StyledSelect
                    placeholder="Coach"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingTop: '5px',
                      border: 'none',

                      color: 'rgba(0, 0, 0, 0.43)',
                      textAlign: 'left'
                    }}
                  >
                    <Option value="Alicia Gerber">Alicia Gerber</Option>
                    <Option value="Konrad Giering">Konrad Giering</Option>
                    <Option value="Yolande Giering">Yolande Giering</Option>
                    <Option value="Lejeanne Hennessey">
                      Lejeanne Hennessey
                    </Option>
                    <Option value="Justin Pietersen">Justin Pietersen</Option>
                    <Option value="Susan Marais">Susan Marais</Option>
                    <Option value="Nadia Snyman">Nadia Snyman</Option>
                    <Option value="Meredith Potgieter">
                      Meredith Potgieter
                    </Option>
                    <Option value="Veronique Newman">Veronique Newman</Option>
                    <Option value="Dantin Broodryk">Dantin Broodryk</Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('memberType', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.memberType
                    : 'Member Type'
                })(
                  <StyledSelect
                    placeholder="Member Type"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingTop: '5px',
                      border: 'none',

                      color: 'rgba(0, 0, 0, 0.43)',
                      textAlign: 'left'
                    }}
                  >
                    <Option value="Non GAN Member">Non GAN Member</Option>
                    <Option value="Club Member">Club Member</Option>
                    <Option value="GAN Member">GAN Member</Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('memberNumber', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.memberNumber
                    : 'New Member'
                })(
                  <Input
                    placeholder="Membership Nr. "
                    style={{
                      color: 'rgba(0, 0, 0, 0.43)',
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingLeft: '20px',
                      border: 'none'
                    }}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('levelPassed', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.levelPassed
                    : ''
                })(
                  <Input
                    placeholder="Level Passed (ex. Level 1)"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingLeft: '20px',
                      border: 'none'
                    }}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('competition', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.competition
                    : 'Section'
                })(
                  <StyledSelect
                    placeholder="Section"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingTop: '5px',
                      border: 'none',

                      color: 'rgba(0, 0, 0, 0.43)',
                      textAlign: 'left'
                    }}
                  >
                    <Option value="Chicks Girls">Chicks Girls</Option>
                    <Option value="Chicks Boys">Chicks Boys</Option>
                    <Option value="Sparrows Girls">Sparrows Girls</Option>
                    <Option value="Sparrows Boys">Sparrows Boys</Option>
                    <Option value="Bronze Hawks Girls">
                      Bronze Hawks Girls
                    </Option>
                    <Option value="Bronze Hawks Boys">Bronze Hawks Boys</Option>
                    <Option value="Silver Falcons women">
                      Silver Falcons women
                    </Option>
                    <Option value="Silver Falcons men">
                      Silver Falcons men
                    </Option>
                    <Option value="Silver Falcons Club Women">
                      Silver Falcons Club Women
                    </Option>
                    <Option value="Silver Falcons Club Men">
                      Silver Falcons Club Men
                    </Option>
                    <Option value="Golden Eagles Women">
                      Golden Eagles Women
                    </Option>
                    <Option value="Golden Eagles Men">Golden Eagles Men</Option>
                    <Option value="Golden Eagles Club Women">
                      Golden Eagles Club Women
                    </Option>
                    <Option value="Golden Eagles Club men">
                      Golden Eagles Club men
                    </Option>
                    <Option value="Emerald Condor Women">
                      Emerald Condor Women
                    </Option>
                    <Option value="Emerald Condor Men">
                      Emerald Condor Men
                    </Option>
                    <Option value="Ruby Condor Women">Ruby Condor Women</Option>
                    <Option value="Ruby Condor Men">Ruby Condor Men</Option>
                    <Option value="Inter Bronze Solo Dance">
                      Inter Bronze Solo Dance
                    </Option>
                    <Option value="Bronze Solo Dance">Bronze Solo Dance</Option>
                    <Option value="Inter Gold Solo Dance">
                      Inter Gold Solo Dance
                    </Option>
                    <Option value="Non GAN Member">Non GAN Member</Option>
                    <Option value="Gold Solo Dance">Gold Solo Dance</Option>
                    <Option value="Inter Silver Solo Dance">
                      Inter Silver Solo Dance
                    </Option>
                    <Option value="Silver Solo Dance">Silver Solo Dance</Option>
                    <Option value="Juvenile Pairs">Juvenile Pairs</Option>
                    <Option value="Synchro">Synchro</Option>
                    <Option value="Juvenile Girls">Juvenile Girls</Option>
                    <Option value="Juvenile Boys">Juvenile Boys</Option>
                    <Option value="Pre-Novice Girls">Pre-Novice Girls</Option>
                    <Option value="Pre-Novice Boys">Pre-Novice Boys</Option>
                    <Option value="Novice Women">Novice Women</Option>
                    <Option value="Novice Men">Novice Men</Option>
                    <Option value="Junior Women">Junior Women</Option>
                    <Option value="Junior Men">Junior Men</Option>
                    <Option value="Senior Women">Senior Women</Option>
                    <Option value="Senior Men">Senior Men</Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('division', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.division
                    : 'Division'
                })(
                  <StyledSelect
                    placeholder="Division"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingTop: '5px',
                      border: 'none',

                      color: 'rgba(0, 0, 0, 0.43)',
                      textAlign: 'left'
                    }}
                  >
                    <Option value="Division A">Division A</Option>
                    <Option value="Division B">Division B</Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col>
            <Col sm={0} md={1} />
            <Col xs={24} sm={24} md={11} style={{ marginBottom: '-1em' }}>
              <FormItem>
                {this.props.form.getFieldDecorator('discipline', {
                  rules: [
                    { required: true, message: 'Field entry required' },
                    {
                      message: 'A name and surname is required'
                    }
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.discipline
                    : 'Discipline'
                })(
                  <StyledSelect
                    placeholder="Discipline"
                    style={{
                      boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                      height: '3.7em',
                      borderRadius: '3em',
                      paddingTop: '5px',
                      border: 'none',

                      color: 'rgba(0, 0, 0, 0.43)',
                      textAlign: 'left'
                    }}
                  >
                    <Option value="Solo Skating">Solo Skating</Option>
                    <Option value="Solo Ice Dance">Solo Ice Dance</Option>
                    <Option value="Pairs Skating">Pairs Skating</Option>
                    <Option value="Synchronised Skating">
                      Synchronised Skating
                    </Option>
                  </StyledSelect>
                )}
              </FormItem>
            </Col> */}
          </Row>
        </Form>
      </div>
    );
  }
}
const UserForm = Form.create()(UserFormClass);
class ManagerFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Name & Surname</span>}>
          {this.props.form.getFieldDecorator("name", {
            rules: [
              { required: true, message: "Manager full name required" },
              {
                pattern: RegExp(
                  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                ),
                message: "A name and surname is required",
              },
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.name
              : "",
          })(
            <Input
              style={{
                boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
                height: "3.7em",
                borderRadius: "3em",
                paddingLeft: "20px",
                border: "none",
              }}
            />
          )}
        </FormItem>
        <FormItem label={<span>Number</span>}>
          {this.props.form.getFieldDecorator("number", {
            rules: [
              { required: true, message: "Manager number required" },
              {
                pattern: RegExp(/\d/g),
                message: "A valid number is required",
              },
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.number
              : "",
          })(<Input maxLength={10} />)}
        </FormItem>
        <FormItem label={<span>Email</span>}>
          {this.props.form.getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Field entry required",
              },
              {
                type: "email",
                message: "A valid email is required.",
              },
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.email
              : "",
          })(
            <Input
              style={{
                boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
                height: "3.7em",
                borderRadius: "3em",
                paddingLeft: "20px",
                border: "none",
              }}
            />
          )}
        </FormItem>
      </Form>
    );
  }
}
const ManagerForm = Form.create()(ManagerFormClass);
class BuyerFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Name & Surname</span>}>
          {this.props.form.getFieldDecorator("name", {
            rules: [
              { required: true, message: "Buyer full name required" },
              {
                pattern: RegExp(
                  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                ),
                message: "A name and surname is required",
              },
            ],
            initialValue: this.props.editBuyer ? this.props.editBuyer.name : "",
          })(
            <Input
              style={{
                boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
                height: "3.7em",
                borderRadius: "3em",
                paddingLeft: "20px",
                border: "none",
              }}
            />
          )}
        </FormItem>
        <FormItem label={<span>Number</span>}>
          {this.props.form.getFieldDecorator("number", {
            rules: [
              { required: true, message: "Buyer number required" },
              {
                pattern: RegExp(/\d/g),
                message: "A valid number is required",
              },
            ],
            initialValue: this.props.editBuyer
              ? this.props.editBuyer.number
              : "",
          })(<Input maxLength={10} />)}
        </FormItem>
        <FormItem label={<span>Email</span>}>
          {this.props.form.getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Field entry required",
              },
              {
                type: "email",
                message: "A valid email is required.",
              },
            ],
            initialValue: this.props.editBuyer
              ? this.props.editBuyer.email
              : "",
          })(
            <Input
              style={{
                boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
                height: "3.7em",
                borderRadius: "3em",
                paddingLeft: "20px",
                border: "none",
              }}
            />
          )}
        </FormItem>
      </Form>
    );
  }
}
const BuyerForm = Form.create()(BuyerFormClass);
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: "",
      editDealer: undefined,
      editManager: undefined,
      editUser: undefined,
    };
  }
  componentWillMount = () => {
    // this.setState({ loading: true });
    // this.props
    //   .findAllUsers()
    //   .then(() => {
    //     this.props
    //       .findAllManagers()
    //       .then(() => {
    //         this.props
    //           .findAllBuyers()
    //           .then(() => {
    //             this.setState({ loading: false });
    //           })
    //           .catch(e => {
    //             this.setState({ loading: false });
    //             Notify('error', e);
    //           });
    //       })
    //       .catch(e => {
    //         this.setState({ loading: false });
    //         Notify('error', e);
    //       });
    //   })
    //   .catch(e => {
    //     this.setState({ loading: false });
    //     Notify('error', e);
    //   });
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };

  handleBack = () => {
    if (this.state.addUserVisible) {
      this.setState({ addUserVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  handleSelectUser = e => {
    this.props.setUserDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/userdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let users = this.props.users;
    if (order === "ascend") {
      users.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      users.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      sortedInfo: sorter,
    });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#F79489" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  userForm;
  onAddUser = e => {
    this.setState({ addUserVisible: true, editUser: undefined });
  };
  handleAddUser = e => {
    e.preventDefault();
    this.userForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editUser) {
          values._id = this.state.editUser._id;
        } else {
          values.type = "user";
          values.password = "pass";
          values.firstLogin = true;
        }
        values.manager = [values.manager];
        this.props
          .addUserClient(values)

          .then(() => {
            if (this.state.editUser) {
              Notify("success", "Successfully saved user");
            } else {
              Notify("success", "Successfully added user");
            }
            this.userForm.props.form.resetFields();
            this.setState({
              loading: false,
              addUserVisible: false,
              editUser: undefined,
            });
            this.props.push("/signin");
          })

          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  // handleEditUser = e => {
  //   this.setState({ addUserVisible: true, editUser: e });
  // };
  // handleDeleteUser = e => {
  //   const id = e.currentTarget.getAttribute('data');
  //   var that = this;
  //   Modal.confirm({
  //     centered: true,
  //     content: (
  //       <div>
  //         <h4>Are your sure you want to delete this user?</h4>
  //       </div>
  //     ),
  //     onOk() {
  //       that.setState({ loading: true });
  //       that.props
  //         .deleteUser(id)
  //         .then(() => {
  //           that.props
  //             .findAllUsers()
  //             .then(() => {
  //               that.setState({ loading: false });
  //               Notify('success', 'Successfully deleted user');
  //             })
  //             .catch(e => {
  //               that.setState({ loading: false });
  //               Notify('error', e);
  //             });
  //         })
  //         .catch(e => {
  //           that.setState({ loading: false });
  //           Notify('error', e);
  //         });
  //     },
  //     oncancel() { }
  //   });
  // };
  // managerForm;
  // onAddManager = e => {
  //   this.setState({ addManagerVisible: true, editManager: undefined });
  // };
  // handleAddManager = e => {
  //   e.preventDefault();
  //   this.managerForm.props.form.validateFieldsAndScroll((err, values) => {
  //     if (!err) {
  //       this.setState({ loading: true });
  //       if (this.state.editManager) {
  //         values._id = this.state.editManager._id;
  //       } else {
  //         values.type = 'manager';
  //         values.password = 'pass';
  //         values.firstLogin = true;
  //       }
  //       this.props
  //         .addManager(values)
  //         .then(() => {
  //           this.props
  //             .findAllManagers()
  //             .then(() => {
  //               if (this.state.editManager) {
  //                 Notify('success', 'Successfully saved manager');
  //               } else {
  //                 Notify('success', 'Successfully added manager');
  //               }
  //               this.managerForm.props.form.resetFields();
  //               this.setState({
  //                 loading: false,
  //                 addManagerVisible: false,
  //                 editManager: undefined
  //               });
  //             })
  //             .catch(e => {
  //               this.setState({ loading: false });
  //               Notify('error', e);
  //             });
  //         })
  //         .catch(e => {
  //           this.setState({ loading: false });
  //           Notify('error', e);
  //         });
  //     }
  //   });
  // };
  // handleEditManager = e => {
  //   this.setState({ addManagerVisible: true, editManager: e });
  // };
  // handleDeleteManager = e => {
  //   const id = e.currentTarget.getAttribute('data');
  //   var that = this;
  //   Modal.confirm({
  //     centered: true,
  //     content: (
  //       <div>
  //         <h4>Are your sure you want to delete this manager?</h4>
  //       </div>
  //     ),
  //     onOk() {
  //       that.setState({ loading: true });
  //       that.props
  //         .deleteUser(id)
  //         .then(() => {
  //           that.props
  //             .findAllManagers()
  //             .then(() => {
  //               that.setState({ loading: false });
  //               Notify('success', 'Successfully deleted manager');
  //             })
  //             .catch(e => {
  //               that.setState({ loading: false });
  //               Notify('error', e);
  //             });
  //         })
  //         .catch(e => {
  //           that.setState({ loading: false });
  //           Notify('error', e);
  //         });
  //     },
  //     oncancel() { }
  //   });
  // };
  // buyerForm;
  // onAddBuyer = e => {
  //   this.setState({ addBuyerVisible: true, editBuyer: undefined });
  // };
  // handleAddBuyer = e => {
  //   e.preventDefault();
  //   this.buyerForm.props.form.validateFieldsAndScroll((err, values) => {
  //     if (!err) {
  //       this.setState({ loading: true });
  //       if (this.state.editBuyer) {
  //         values._id = this.state.editBuyer._id;
  //       } else {
  //         values.type = 'buyer';
  //         values.password = 'pass';
  //         values.firstLogin = true;
  //       }
  //       this.props
  //         .addBuyer(values)
  //         .then(() => {
  //           this.props
  //             .findAllBuyers()
  //             .then(() => {
  //               if (this.state.editBuyer) {
  //                 Notify('success', 'Successfully saved buyer');
  //               } else {
  //                 Notify('success', 'Successfully added buyer');
  //               }
  //               this.buyerForm.props.form.resetFields();
  //               this.setState({
  //                 loading: false,
  //                 addBuyerVisible: false,
  //                 editBuyer: undefined
  //               });
  //             })
  //             .catch(e => {
  //               this.setState({ loading: false });
  //               Notify('error', e);
  //             });
  //         })
  //         .catch(e => {
  //           this.setState({ loading: false });
  //           Notify('error', e);
  //         });
  //     }
  //   });
  // };
  // handleEditBuyer = e => {
  //   this.setState({ addBuyerVisible: true, editBuyer: e });
  // };
  // handleDeleteBuyer = e => {
  //   const id = e.currentTarget.getAttribute('data');
  //   var that = this;
  //   Modal.confirm({
  //     centered: true,
  //     content: (
  //       <div>
  //         <h4>Are your sure you want to delete this buyer?</h4>
  //       </div>
  //     ),
  //     onOk() {
  //       that.setState({ loading: true });
  //       that.props
  //         .deleteUser(id)
  //         .then(() => {
  //           that.props
  //             .findAllBuyers()
  //             .then(() => {
  //               that.setState({ loading: false });
  //               Notify('success', 'Successfully deleted buyer');
  //             })
  //             .catch(e => {
  //               that.setState({ loading: false });
  //               Notify('error', e);
  //             });
  //         })
  //         .catch(e => {
  //           that.setState({ loading: false });
  //           Notify('error', e);
  //         });
  //     },
  //     oncancel() { }
  //   });
  // };
  render() {
    const { user } = this.props;
    return (
      <div
        style={{
          background: "white",
          maxWidth: "60em",
          textAlign: "center",
          margin: "auto",
        }}
      >
        <Spin spinning={this.state.loading}>
          <UserForm
            // managers={this.props.managers}
            // editUser={this.state.editUser}
            wrappedComponentRef={form => (this.userForm = form)}
          />
          <strong>
            Please complete all fields, and we will send your login details via
            email.
          </strong>
          <br />
          <ButtonRadius
            onClick={this.handleAddUser}
            style={{
              marginTop: "1.2em",
              height: 55,
              width: "40%",
              fontSize: 14,
              border: "none",
              fontFamily: "Open Sans",
              textShadow: "none",
              backgroundColor: "#F79489",
            }}
            type="primary"
            htmlType="submit"
            size="large"
            block
          >
            Join
          </ButtonRadius>
          <br />
          <br />
          Already have an account?{" "}
          <Link style={{ paddingBottom: "5em" }} to="/signin">
            Sign In
          </Link>
        </Spin>
      </div>
    );
  }
}

const WrappedUsers = Form.create()(Users);

export default WrappedUsers;
