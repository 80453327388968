import React, { PureComponent } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
  BellFilled,
  NotificationOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
  MessageOutlined,
  BellOutlined,
  PhoneOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  MailOutlined,
  CaretRightOutlined,
  UserOutlined
} from '@ant-design/icons';

import {
  Layout,
  Row,
  Col,
  Popover,
  Menu,
  Modal,
  Upload,
  Badge,
  notification,
  List,
  Space,
  Typography,
  Button,
  PageHeader
} from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import logo from 'src/static/logo-white.svg';
import MenuIcon from 'src/static/menu-icon.svg';
import MenuOpenIcon from 'src/static/menu-open-icon.svg';
import logoWhite from 'src/static/logo-white.svg';
import { Cookies } from 'react-cookie';
import { saveAccountInfo } from 'src/redux/actions/user';
import {
  getSidebarCollapsed,
  getUser,
  getNotifications
} from 'src/redux/selectors';

import { ButtonSquare } from 'src/components/elements/Button';
import { push } from 'connected-react-router';
import { collapseSidebar } from 'src/redux/actions/status';
import Resizer from 'react-image-file-resizer';
import Notify from 'src/components/meta/Notification';
import { setStockDetailsId } from 'src/redux/actions/stock';
import {
  findNotifications,
  removeNotification
} from 'src/redux/actions/notifications';
import LogoSmall from '../../static/logo-small.png';
const { Title } = Typography;

const signOut = s => {
  if (window.cordova) {
    window.FirebasePlugin.getToken(
      token => {
        window.pubnub.push.deleteDevice(
          {
            device: token,
            pushGateway: 'gcm' // apns, gcm, mpns
          },
          function (status) {
            if (status.error) {
              console.log('operation failed w/ error:', status);
            }
          }
        );
      },
      error => {
        console.error(error);
      }
    );
  }

  const cookies = new Cookies();
  cookies.remove('token');
  cookies.remove('basic');
  localStorage.clear();
  window.location.reload();
};

if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
export class ResHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: 'gcm' // apns, gcm, mpns
            },
            function (status) {
              if (status.error) {
                console.log('operation failed w/ error:', status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove('token');
    cookies.remove('basic');
    localStorage.clear();
    window.location.reload();
  };

  exit = false;
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Layout.Header
        style={{
          zIndex: 99,
          // display: window.isPhone ? 'none' : '',
          background: 'white',
          height: 50,
          position: 'fixed',
          width: this.props.collapsed
            ? 'calc(100% - 50px)'
            : 'calc(100% - 210px)',
          marginLeft: window.isPhone ? 40 : 99,
          paddingLeft: 10,
          paddingRight: window.isPhone ? 0 : 40,
          boxShadow: '0px 0px 20px 8px rgba(155, 155, 155, 0.1)'
        }}
      >
        <Col
          span={24}
          style={{
            margin: 'auto',
            borderLeft: '0px solid #E5E5E5'
          }}
        >
          <Popover
            placement="bottomRight"
            content={
              <div>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={6}>
                    {this.props.user.image ? (
                      <div
                        onClick={e =>
                          this.setState({
                            imageVisible: true,
                            image: this.props.user.image
                          })
                        }
                        style={{
                          border: '1px solid #F794893b',
                          cursor: 'pointer',
                          height: 40,
                          width: 40,
                          margin: 'auto',
                          borderRadius: '50%',
                          display: 'flex',
                          overflow: 'hidden'
                        }}
                      >
                        <img
                          style={{ margin: 'auto', width: 40 }}
                          alt="Profile"
                          src={this.props.user.image}
                        />
                      </div>
                    ) : (
                      <div
                        onClick={e => this.setState({ imageVisible: true })}
                        style={{
                          cursor: 'pointer',
                          height: 40,
                          width: 40,
                          margin: 'auto',
                          borderRadius: '50%',
                          backgroundColor: '#F79489',
                          display: 'flex'
                        }}
                      >
                        <span
                          style={{
                            margin: 'auto',
                            lineHeight: '40px',
                            fontSize: 35,
                            color: 'white'
                          }}
                        >
                          {this.props.user.name.substr(0, 1)}
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col span={17}>
                    <div style={{ margin: '0px 0px 8px 0px', width: 250 }}>
                      <span style={{ fontSize: 13 }}>
                        <strong>Username : </strong> {this.props.user.username}
                      </span>
                    </div>

                    {/*<ButtonSquare
                      style={{ height: 35 }}
                      onClick={(e) => {
                        if (this.props.user.type === "manager") {
                          this.props.push("/manager/account");
                        } else if (this.props.user.type === "user") {
                          this.props.push("/user/account");
                        } else if (this.props.user.type === "admin") {
                          this.props.push("/admin/account");
                        }
                      }}
                      type='primary'
                    >
                      My Settings
                    </ButtonSquare>*/}
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  style={{ marginTop: 20 }}
                >
                  <Menu
                    style={{ background: 'none' }}
                    theme="light"
                    mode="inline"
                  >
                    <Menu.Item onClick={this.signOut} key="signout">
                      <PoweroffOutlined />
                      <span>Sign Out</span>
                    </Menu.Item>
                  </Menu>
                </Row>
              </div>
            }
            trigger="click"
          // visible={this.state.visible}
          >
            {this.props.user.image ? (
              <div
                onClick={e => this.setState({ visible: !this.state.visible })}
                style={{
                  border: '1px solid #F794893b',
                  cursor: 'pointer',
                  height: 40,
                  width: 40,
                  margin: 'auto',
                  borderRadius: '50%',
                  display: 'flex',
                  overflow: 'hidden'
                }}
              >
                <img
                  style={{ margin: 'auto', width: 40 }}
                  alt="Profile"
                  src={this.props.user.image}
                />
              </div>
            ) : (
              <div

              //style={{ display: "none" }}
              //onClick={(e) => {
              //  this.setState({ visible: !this.state.visible });
              //}}
              >
                <p
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#F79489',
                    fontWeight: 700,
                    height: '3.2em',
                    paddingRight: '1em',
                    //backgroundColor: "#F79489",
                    margin: 'auto',
                    float: 'left',
                    marginTop: '-0.6em',
                    marginLeft: '1em'
                    //boxShadow: "rgb(0 0 0 / 7%) 0px 5px 10px 1px",
                  }}
                >
                  <UserOutlined /> {this.props.user.name}
                </p>
              </div>
            )}
          </Popover>{' '}
          <p
            onClick={this.signOut}
            style={{
              color: '#F79489',
              fontWeight: 700,
              whiteSpace: 'nowrap',
              height: '3.2em',
              paddingRight: '1em',
              //backgroundColor: "#F79489",
              float: 'right',
              margin: 'auto',
              marginTop: '-0.6em'
            }}
          >
            Logout <CaretRightOutlined />
          </p>
        </Col>{' '}
      </Layout.Header>
    );
  }
}

const mapStateToProps = state => ({
  collapsed: getSidebarCollapsed(state),
  notifications: getNotifications(state) || [],
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  collapseSidebar,
  push,
  saveAccountInfo,
  setStockDetailsId,
  findNotifications,
  removeNotification
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResHeader)
);
