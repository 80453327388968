import React from "react";
import {
  SearchOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import "react-responsive-modal/styles.css";
import { Modal as SuccessModal } from "react-responsive-modal";
import LogoModal from "src/static/logo2.svg";
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Card,
  Checkbox,
  Badge,
} from "antd";
import Highlighter from "react-highlight-words";
import Notify from "src/components/meta/Notification";
import styled from "styled-components";
import { regExpLiteral } from "@babel/types";
const { Column, ColumnGroup } = Table;
const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const numeral = require("numeral");
const { TabPane } = Tabs;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #8898aa !important;
    background: #f1f1f1 !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    font-weight: 500;
    color: #525f7f;
    font-size: 15px;
  }
`;

const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
  .ant-select-selection__rendered:after {
    content: ".";
    display: inline !important;
    visibility: visible !important;
    pointer-events: none;

    width: 0;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid lightgrey;
  border-radius: 0em !important;
  height: 47px !important;
  box-shadow: none !important;
  :hover {
    border-color: red !important;

    box-shadow: none !important;
  }
  :active {
    border-color: red !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: red !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
class UserFormClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      w1English:
        " Weereens welkom by Duepoint as ‘s splinternuwe “Wealth Engineer”. ",
      w1English2:
        "Mag jy geseënd wees en ‘n uiters suksesvolle loopbaan geniet saam met ons Team Eagle span.",
      successModal: false,
    };
    this.onFinish = this.onFinish.bind(this);
  }
  handleSubmit = values => {
    this.onFinish(values);
  };
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    // window.location.assign("./");
  };
  onFinish = values => {
    const MSG = {
      to: this.props.editUser ? this.props.editUser.email : "",
      // this.props.editUser.name
      from: "info@perpetualincome.co.za",
      //to: ["ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "Profile Activation",
      text: " ",
      html: `
      
      <style type="text/css">
      body, #bodyTable{background - color:white}
      #emailHeader{background - color:white}
      #emailBody{background - color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background - color:#E1E1E1;}
    </style>
    <body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
      <center style="background-color:#E1E1E1;">
        <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
          <tr>
            <td align="center" valign="top" id="bodyCell">
              <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
                <!-- HEADER ROW // -->
                <tr>
                  <td align="center" valign="top">
                    <!-- CENTERING TABLE // -->
                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                      <tr>
                        <td align="center" valign="top">
                          <!-- FLEXIBLE CONTAINER // -->
                          <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                            <tr>
                              <td valign="top" width="500" class="flexibleContainerCell">

                                <!-- CONTENT TABLE // -->
                                <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                  <tr>

                                    <td align="right" valign="middle" class="flexibleContainerBox">
                                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                        <tr>
                                          <td align="left" class="textContent">
                                            <!-- CONTENT // -->

                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                          <!-- // FLEXIBLE CONTAINER -->
                        </td>
                      </tr>
                    </table>
                    <!-- // CENTERING TABLE -->
                  </td>
                </tr>
                <!-- // END -->
              </table>
              <!-- EMAIL BODY // -->
              <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500"style="padding-bottom:3em;" id="emailBody">
                <!-- MODULE ROW // -->
                <tr>
                  <td align="center" valign="top">
                    <!-- CENTERING TABLE // -->
                    <table border="0" cellpadding="0" cellspacing="0" style="color:#FFFFFF;" bgcolor="white">
                      <tr>
                        <td align="center" valign="top">
                          <!-- FLEXIBLE CONTAINER // -->

                          <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                            <tr>
                              <td align="center" valign="top" width="500" class="flexibleContainerCell">

                                <!-- CONTENT TABLE // -->

                                <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                                  <td align="center" valign="top" width="500"  class="flexibleContainerCell  ">
                                    <img alt="" src="https://i.ibb.co/2dRzKr7/Group-10131.png" width="300" height="70" style="display: block; width:348px; max-width: 250px;; min-width: 189px;;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                                      <td align="center" valign="top" class="textContent">
                                        <h1 style="color:black;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;margin-top:20px;text-align:center;">Profile Activated</h1>
                                        <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color:black;line-height:135%;">Please note that your Perpetual Income Profile is now active. <br /><br /><strong>Your profile email:</strong><br />
                                      
                                        
                                        ${this.props.editUser.email
        }<br /><br />Enter <strong>1234</strong> in the password field to create a new password<br /><br />Follow the link below to login to your account:<br /><a href="https://perpetualincome.org/login/" target="_blank">https://perpetualincome.org/login/</a><br /><br />  For troubleshooting or queries<a href="mailto:admin@squatchwebsites.co.za"> ask us here</a></h2>
                                      </td>
                                    </tr>
                                    </table>
                                    <!-- // CONTENT TABLE -->
                                  </td>
                                </tr>
                              </table>
                              <!-- // FLEXIBLE CONTAINER -->
                            </td>
                          </tr>
                        </table>
                        <!-- // CENTERING TABLE -->
                      </td>
                    </tr>
                    <!-- MODULE ROW // -->
                
                        <!-- // MODULE ROW -->


                        <!-- MODULE ROW // -->

                      
                              <!-- // FLEXIBLE CONTAINER -->
                            </td>
                          </tr>
                        </table>
                              `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async res => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        email: "",
        number: "",
        message: "",
      });
    });
  };

  render() {
    return (
      <span>
        <Form layout="vertical">
          <Row>
            <Col span={24} style={{ marginBottom: "-1em" }}>
              <FormItem style={{ float: "right" }} label={<span>Status</span>}>
                {this.props.form.getFieldDecorator("paymentStatus", {
                  // normalize: input => input.toUpperCase(),
                  rules: [
                    {
                      required: false,
                      message: "Please Select a Status",
                    },
                  ],
                  initialValue: "Not Allowed",
                })(
                  <select>
                    <option value="" disabled selected>
                      Select option
                    </option>
                    <option key={"Allow"} value={"Allow"}>
                      Allow
                    </option>
                    <option key={"Not Allowed"} value={"Not Allowed"}>
                      Not Allowed
                    </option>
                  </select>
                )}
                <br />
                <Button
                  style={{
                    fontWeight: 500,

                    fontSize: "15px",
                    backgroundColor: "#F79489",
                    border: "black",
                    marginTop: "1em",
                  }}
                  type="primary"
                  onClick={this.handleSubmit}
                  htmlType="submit"
                >
                  Send Notification
                </Button>
              </FormItem>
            </Col>
            <Col span={10} style={{ marginBottom: "-1em" }}>
              <FormItem label={<span>Full Name</span>}>
                {this.props.form.getFieldDecorator("name", {
                  rules: [
                    { required: true, message: "Field entry required" },
                    {
                      //pattern: RegExp(
                      //  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                      //),
                      message: "A name and surname is required",
                    },
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.name
                    : "",
                })(this.props.editUser ? <Input disabled /> : <Input />)}
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={10} style={{ marginBottom: "-1em" }}>
              <FormItem label={<span>Number</span>}>
                {this.props.form.getFieldDecorator("number", {
                  rules: [
                    { required: true, message: "User number required" },
                    {
                      pattern: RegExp(/\d/g),
                      message: "A valid number is required",
                    },
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.number
                    : "",
                })(
                  this.props.editUser ? (
                    <Input maxLength={10} disabled />
                  ) : (
                    <Input maxLength={10} />
                  )
                )}
              </FormItem>
            </Col>
            <Col span={2} />
            <Col span={10} style={{ marginBottom: "-1em" }}>
              <FormItem label={<span>Email</span>}>
                {this.props.form.getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      message: "Field entry required",
                    },
                    {
                      type: "email",
                      message: "Field entry required.",
                    },
                  ],
                  initialValue: this.props.editUser
                    ? this.props.editUser.email
                    : "",
                })(this.props.editUser ? <Input disabled /> : <Input />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <p>Stuur Boodskap (Afrikaans)</p>
              {/* <a
                href={
                  "https://wa.me/" +
                  "+27" +
                  this.props.editUser.number +
                  "?text=Hello, " +
                  this.props.editUser.name +
                  "%0A" +
                  "%0A" +
                  this.state.w1English +
                  "%0A" +
                  this.state.w1English2
                }
                target="_blank"
              >
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W1(A)
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W2(A)
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W3(A)
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W4(A)
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W5(A)
                </Button>
              </a> */}

              <br />
              <br />
              <p>Send Message (English)</p>
              {/* <a
                href={
                  "https://wa.me/" +
                  "+27" +
                  this.props.editUser.number +
                  "?text=Hello, " +
                  this.props.editUser.name +
                  this.state.w1English +
                  "%0A" +
                  this.state.w1English2
                }
                target="_blank"
              >
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W1(E)
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W2(E)
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W2.1(E)
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W3(E)
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W4(E)
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    marginRight: "0.5em",
                    padding: 0,
                    height: "2em",
                    width: "4em",
                  }}
                >
                  W5(E)
                </Button>
              </a> */}
            </Col>
            {/* <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Country of Birth</span>}>
              {this.props.form.getFieldDecorator('countryOfBirth', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.countryOfBirth
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>ID/Passport Number</span>}>
              {this.props.form.getFieldDecorator('identityNumber', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.identityNumber
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Address</span>}>
              {this.props.form.getFieldDecorator('address', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.address
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Race</span>}>
              {this.props.form.getFieldDecorator('race', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.race
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Gender</span>}>
              {this.props.form.getFieldDecorator('gender', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.gender
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Club</span>}>
              {this.props.form.getFieldDecorator('club', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.club
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Skating School</span>}>
              {this.props.form.getFieldDecorator('school', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.school
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Head Coach</span>}>
              {this.props.form.getFieldDecorator('coach', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.coach
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Member Type</span>}>
              {this.props.form.getFieldDecorator('memberType', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.memberType
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Membership Number</span>}>
              {this.props.form.getFieldDecorator('memberNumber', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.memberNumber
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Level Passed</span>}>
              {this.props.form.getFieldDecorator('levelPassed', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.levelPassed
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Section</span>}>
              {this.props.form.getFieldDecorator('competition', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.competition
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Division</span>}>
              {this.props.form.getFieldDecorator('division', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.division
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Discipline</span>}>
              {this.props.form.getFieldDecorator('discipline', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.discipline
                  : ''
              })(this.props.editUser ? <Input disabled /> : <Input />)}
            </FormItem>
          </Col> */}
          </Row>
          {/*<FormItem label={<span>Brand</span>}>
          {this.props.form.getFieldDecorator("brand", {
            rules: [{ required: false, message: "Brand is required" }],
            initialValue: this.props.editUser ? this.props.editUser.brand : "",
          })(
            <Select>
              <Option value='megatheron'>Mega Theron</Option>
              <Option value='kia'>Kia</Option>
              <Option value='mahindra'>Mahindra</Option>
              <Option value='chery'>Chery</Option>
              <Option value='gwm'>GWM</Option>
              <Option value='haval'>Haval</Option>
            </Select>
          )}
        </FormItem>
        <FormItem label={<span>Branch</span>}>
          {this.props.form.getFieldDecorator("branch", {
            rules: [
              {
                required: false,
                message: "Branch is required",
              },
            ],
            initialValue: this.props.editUser ? this.props.editUser.branch : "",
          })(
            <Select>
              <Option value='klerksdorp'>Klerksdorp</Option>
              <Option value='potchefstroom'>Potchefstroom</Option>
              <Option value='mahikeng'>Mahikeng</Option>
              <Option value='lichtenburg'>Lichtenburg</Option>
            </Select>
          )}
        </FormItem>*/}
          {/*<FormItem label={"Roles Stock"}>
          {this.props.form.getFieldDecorator("isAdd", {
            valuePropName: "checked",
            initialValue: this.props.editUser
              ? this.props.editUser.isAdd
                ? true
                : false
              : false,
          })(<Checkbox>Add Stock</Checkbox>)}
          {this.props.form.getFieldDecorator("isEdit", {
            valuePropName: "checked",
            initialValue: this.props.editUser
              ? this.props.editUser.isEdit
                ? true
                : false
              : false,
          })(<Checkbox>Edit Stock</Checkbox>)}
          {this.props.form.getFieldDecorator("isDelete", {
            valuePropName: "checked",
            initialValue: this.props.editUser
              ? this.props.editUser.isDelete
                ? true
                : false
              : false,
          })(<Checkbox>Delete Stock</Checkbox>)}*/}
          {/*{this.props.form.getFieldDecorator('isBatch', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isBatch
                ? true
                : false
              : false
          })(<Checkbox>Batch Request</Checkbox>)}*/}
          {/*</FormItem>*/}
          {/*<FormItem>
          {this.props.form.getFieldDecorator('isStockOut', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isStockOut
                ? true
                : false
              : false
          })(<Checkbox>Submit Stock Out</Checkbox>)}
        </FormItem>*/}
          {/*<FormItem label={'Roles PEX'}>
          {this.props.form.getFieldDecorator('isPexAdd', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isPexAdd
                ? true
                : false
              : false
          })(<Checkbox>Add Stock</Checkbox>)}
          {this.props.form.getFieldDecorator('isPexEdit', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isPexEdit
                ? true
                : false
              : false
          })(<Checkbox>Edit Stock</Checkbox>)}
          {this.props.form.getFieldDecorator('isPexDelete', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isPexDelete
                ? true
                : false
              : false
          })(<Checkbox>Delete Stock</Checkbox>)}
        </FormItem>
        <FormItem>
          {this.props.form.getFieldDecorator('isPexOut', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isPexOut
                ? true
                : false
              : false
          })(<Checkbox>PEX Unit Out</Checkbox>)}
        </FormItem>*/}
        </Form>
        <SuccessModal
          open={this.state.successModal}
          onClose={this.handleCloseSuccessModal}
          center
          style={{ textAlign: "center" }}
        >
          <img src={LogoModal} alt="Logo" />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
            }}
          >
            Allow Notification Sent
          </h3>{" "}
          <br />
          <Button
            onClick={this.handleCloseSuccessModal}
            style={{
              margin: "auto",
              height: "2.9em",
              width: "100%",
              fontSize: "1rem",
              fontWeight: 500,
              borderRadius: "5em",
              backgroundColor: "#F79489",
              color: "white",
              border: "none",
              marginBottom: "1em",
              paddingLeft: "3em",
              paddingRight: "3em",
              marginRight: "0em",
              textShadow: "none",
            }}
            type="primary"
          >
            Close
          </Button>{" "}
        </SuccessModal>
      </span>
    );
  }
}
const UserForm = Form.create()(UserFormClass);
class ManagerFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Full Name</span>}>
          {this.props.form.getFieldDecorator("name", {
            rules: [
              { required: true, message: "Manager full name required" },
              {
                pattern: RegExp(
                  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                ),
                message: "A name and surname is required",
              },
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.name
              : "",
          })(this.props.editUser ? <Input disabled /> : <Input />)}
        </FormItem>
        <FormItem label={<span>Number</span>}>
          {this.props.form.getFieldDecorator("number", {
            rules: [
              { required: false, message: "Manager number required" },
              {
                pattern: RegExp(/\d/g),
                message: "A valid number is required",
              },
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.number
              : "",
          })(<Input maxLength={10} />)}
        </FormItem>
        <FormItem label={<span>Email</span>}>
          {this.props.form.getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Field entry required",
              },
              {
                type: "email",
                message: "Field entry required.",
              },
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.email
              : "",
          })(this.props.editUser ? <Input disabled /> : <Input />)}
        </FormItem>
      </Form>
    );
  }
}
const ManagerForm = Form.create()(ManagerFormClass);
class BuyerFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Full Name</span>}>
          {this.props.form.getFieldDecorator("name", {
            rules: [
              { required: true, message: "Buyer full name required" },
              {
                pattern: RegExp(
                  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                ),
                message: "A name and surname is required",
              },
            ],
            initialValue: this.props.editBuyer ? this.props.editBuyer.name : "",
          })(this.props.editUser ? <Input disabled /> : <Input />)}
        </FormItem>
        <FormItem label={<span>Number</span>}>
          {this.props.form.getFieldDecorator("number", {
            rules: [
              { required: true, message: "Buyer number required" },
              {
                pattern: RegExp(/\d/g),
                message: "A valid number is required",
              },
            ],
            initialValue: this.props.editBuyer
              ? this.props.editBuyer.number
              : "",
          })(<Input maxLength={10} />)}
        </FormItem>
        <FormItem label={<span>Email</span>}>
          {this.props.form.getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Field entry required",
              },
              {
                type: "email",
                message: "Field entry required.",
              },
            ],
            initialValue: this.props.editBuyer
              ? this.props.editBuyer.email
              : "",
          })(this.props.editUser ? <Input disabled /> : <Input />)}
        </FormItem>
      </Form>
    );
  }
}
const BuyerForm = Form.create()(BuyerFormClass);
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: "",
      editDealer: undefined,
      editManager: undefined,
      editUser: undefined,
    };
  }
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllUsers()
      .then(() => {
        this.props
          .findAllManagers()
          .then(() => {
            this.props
              .findAllBuyers()
              .then(() => {
                this.setState({ loading: false });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };

  handleBack = () => {
    if (this.state.addUserVisible) {
      this.setState({ addUserVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  handleSelectUser = e => {
    this.props.setUserDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/userdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let users = this.props.users;
    if (order === "ascend") {
      users.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      users.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      sortedInfo: sorter,
    });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#F79489" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  userForm;
  onAddUser = e => {
    this.setState({ addUserVisible: true, editUser: undefined });
  };
  handleAddUser = e => {
    e.preventDefault();
    this.userForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editUser) {
          values._id = this.state.editUser._id;
        } else {
          values.type = "user";
          values.password = "pass";
          values.firstLogin = true;
        }
        values.manager = [values.manager];
        this.props
          .addUser(values)
          .then(() => {
            this.props
              .findAllUsers()
              .then(() => {
                if (this.state.editUser) {
                  Notify("success", "Successfully saved user");
                } else {
                  Notify("success", "Successfully added user");
                }
                this.userForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addUserVisible: false,
                  editUser: undefined,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleEditUser = e => {
    this.setState({ addUserVisible: true, editUser: e });
  };
  handleDeleteUser = e => {
    const id = e.currentTarget.getAttribute("data");
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this user?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllUsers()
              .then(() => {
                that.setState({ loading: false });
                Notify("success", "Successfully deleted user");
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify("error", e);
          });
      },
      oncancel() { },
    });
  };
  managerForm;
  onAddManager = e => {
    this.setState({ addManagerVisible: true, editManager: undefined });
  };
  handleAddManager = e => {
    e.preventDefault();
    this.managerForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editManager) {
          values._id = this.state.editManager._id;
        } else {
          values.type = "manager";
          values.password = "pass";
          values.firstLogin = true;
        }
        this.props
          .addManager(values)
          .then(() => {
            this.props
              .findAllManagers()
              .then(() => {
                if (this.state.editManager) {
                  Notify("success", "Successfully saved manager");
                } else {
                  Notify("success", "Successfully added manager");
                }
                this.managerForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addManagerVisible: false,
                  editManager: undefined,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleEditManager = e => {
    this.setState({ addManagerVisible: true, editManager: e });
  };
  handleDeleteManager = e => {
    const id = e.currentTarget.getAttribute("data");
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this manager?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllManagers()
              .then(() => {
                that.setState({ loading: false });
                Notify("success", "Successfully deleted manager");
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify("error", e);
          });
      },
      oncancel() { },
    });
  };
  buyerForm;
  onAddBuyer = e => {
    this.setState({ addBuyerVisible: true, editBuyer: undefined });
  };
  handleAddBuyer = e => {
    e.preventDefault();
    this.buyerForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editBuyer) {
          values._id = this.state.editBuyer._id;
        } else {
          values.type = "buyer";
          values.password = "pass";
          values.firstLogin = true;
        }
        this.props
          .addBuyer(values)
          .then(() => {
            this.props
              .findAllBuyers()
              .then(() => {
                if (this.state.editBuyer) {
                  Notify("success", "Successfully saved buyer");
                } else {
                  Notify("success", "Successfully added buyer");
                }
                this.buyerForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addBuyerVisible: false,
                  editBuyer: undefined,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleEditBuyer = e => {
    this.setState({ addBuyerVisible: true, editBuyer: e });
  };
  handleDeleteBuyer = e => {
    const id = e.currentTarget.getAttribute("data");
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this buyer?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllBuyers()
              .then(() => {
                that.setState({ loading: false });
                Notify("success", "Successfully deleted buyer");
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify("error", e);
          });
      },
      oncancel() { },
    });
  };
  render() {
    const { user } = this.props;
    return (
      <div style={{ maxWidth: "90em", margin: "auto" }}>
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{ textAlign: "center" }}
        >
          {/*<Col xs={24} md={12} style={{ marginLeft: "-0.8em" }}>
            <TeamOutlined
              style={{ fontSize: 25, color: "#EC8144", marginRight: 7 }}
            />
            <span style={{ fontWeight: 750, fontSize: 25 }}>Manage People</span>
          </Col>*/}
          <Col
            style={{ display: "flex", justifyContent: "flex-start" }}
            xs={24}
            md={24}
          >
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              <Button
                type="primary"
                shape="round"
                onClick={this.onAddUser}
                icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                size="large"
                style={{
                  marginRight: "2.5em",
                  height: "3em",
                  width: "9em",
                }}
              >
                Add User
              </Button>

              {/*
                   <Button
                type='primary'
                style={{ height: "3em", marginRight: 15 }}
                onClick={this.onAddUser}
              >
                Add User
              </Button><Button
                type='primary'
                style={{ height: "3em", marginRight: 15 }}
                onClick={this.onAddManager}
              >
                Add Manager
              </Button>
              <Button
                type='primary'
                style={{ height: "3em" }}
                onClick={this.onAddBuyer}
              >
                Add Buyer
              </Button>*/}
            </div>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Tabs style={{ overflow: "visible" }}>
            <TabPane tab="Users" key="1">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: "white" }}
                    rowKey="_id"
                    size="middle"
                    pagination={{
                      defaultPageSize: 25,
                    }}
                    expandedRowRender={
                      window.innerWidth <= 420
                        ? (record, index, indent, expanded) => (
                          <Card
                            size="small"
                            title={record.name}
                            style={{ width: 300, marginLeft: -35 }}
                          >
                            <p>Number: {record.number}</p>
                            <p>Email: {record.email}</p>
                            <p>Identity Number: {record.identityNumber}</p>
                          </Card>
                        )
                        : null
                    }
                    dataSource={this.props.users}
                    onChange={this.handleSort}
                  >
                    <Column
                      {...this.getColumnSearchProps("username")}
                      title="Username"
                      dataIndex="username"
                      key="username"
                    />
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("name")}
                        title="Full Name"
                        dataIndex="name"
                        key="name"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("number")}
                        title="Number"
                        dataIndex="number"
                        key="number"
                        render={(text, user) => (
                          <a
                            href={
                              "https://wa.me/" +
                              "+27" +
                              user.number +
                              "?text=Hello, " +
                              user.name +
                              ", welcome to Duepoint."
                            }
                            target="_blank"
                          >
                            {user.number}
                          </a>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("email")}
                        title="Email"
                        dataIndex="email"
                        key="email"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        dataIndex="paymentStatus"
                        title="Access"
                        render={(text, user) => (
                          <span>
                            {user.paymentStatus === "Allow" ? (
                              <span>
                                <StyledBadge color="green" style={{}} /> Allowed{" "}
                              </span>
                            ) : (
                              <span>
                                <StyledBadge color="red" style={{}} /> Not
                                Allowed{" "}
                              </span>
                            )}
                          </span>
                        )}
                      />
                    ) : null}
                    {/*{window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("brand")}
                        title='Brand'
                        dataIndex='brand'
                        key='brand'
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("branch")}
                        title='Branch'
                        dataIndex='branch'
                        key='branch'
                      />
                    ) : null}*/}

                    {/*{window.innerWidth > 420 ? (
                      <Column
                        width={70}
                        title='Batch Request'
                        render={(text, request) => (
                          <span>{request.isBatch ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={80}
                        title='Submit Stock Out'
                        render={(text, request) => (
                          <span>{request.isStockOut ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={65}
                        title='Add PEX'
                        render={(text, request) => (
                          <span>{request.isPexAdd ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={65}
                        title='Edit PEX'
                        render={(text, request) => (
                          <span>{request.isPexEdit ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={65}
                        title='Del PEX'
                        render={(text, request) => (
                          <span>{request.isPexDelete ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={80}
                        title='PEX Unit Out'
                        render={(text, request) => (
                          <span>{request.isPexOut ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}*/}
                    <Column
                      title="Actions"
                      render={(text, user) => (
                        <span>
                          <Divider type="vertical" />
                          <Button
                            style={{
                              backgroundColor: "#F79489",
                              color: "white",
                              borderRadius: "3em",
                            }}
                            onClick={e => this.handleEditUser(user)}
                          >
                            View
                          </Button>
                          <Divider type="vertical" />
                          <DeleteFilled
                            style={{
                              backgroundColor: "#CFD5E3",
                              color: "white",
                              padding: "0.5em",
                              borderRadius: "3em",
                            }}
                            data={user._id}
                            onClick={this.handleDeleteUser}
                          />
                        </span>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            {/*<TabPane tab='Managers' key='2'>
              <Row type='flex' justify='start' align='middle'>
                <Col span={24}>
                  <StyledTable
                    style={{ background: "white" }}
                    rowKey='_id'
                    size='middle'
                    pagination={{
                      defaultPageSize: 25,
                    }}
                    dataSource={this.props.managers}
                    onChange={this.handleSort}
                  >
                    <Column
                      {...this.getColumnSearchProps("username")}
                      title='Username'
                      dataIndex='username'
                      key='username'
                    />
                    <Column
                      {...this.getColumnSearchProps("name")}
                      title='Full Name'
                      dataIndex='name'
                      key='name'
                    />
                    <Column
                      {...this.getColumnSearchProps("number")}
                      title='Number'
                      dataIndex='number'
                      key='number'
                    />
                    <Column
                      {...this.getColumnSearchProps("email")}
                      title='Email'
                      dataIndex='email'
                      key='email'
                    />

                    <Column
                      title='Actions'
                      render={(text, manager) => (
                        <span>
                          <a onClick={(e) => this.handleEditManager(manager)}>
                            Edit
                          </a>
                          <Divider type='vertical' />
                          <a
                            data={manager._id}
                            onClick={this.handleDeleteManager}
                          >
                            Delete
                          </a>
                        </span>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab='Buyers' key='3'>
              <Row type='flex' justify='start' align='middle'>
                <Col span={24}>
                  <StyledTable
                    style={{ background: "white" }}
                    rowKey='_id'
                    size='middle'
                    pagination={{
                      defaultPageSize: 25,
                    }}
                    dataSource={this.props.buyers}
                    onChange={this.handleSort}
                  >
                    <Column
                      {...this.getColumnSearchProps("username")}
                      title='Username'
                      dataIndex='username'
                      key='username'
                    />
                    <Column
                      {...this.getColumnSearchProps("name")}
                      title='Full Name'
                      dataIndex='name'
                      key='name'
                    />
                    <Column
                      {...this.getColumnSearchProps("number")}
                      title='Number'
                      dataIndex='number'
                      key='number'
                    />
                    <Column
                      {...this.getColumnSearchProps("email")}
                      title='Email'
                      dataIndex='email'
                      key='email'
                    />

                    <Column
                      title='Actions'
                      render={(text, buyer) => (
                        <span>
                          <a onClick={(e) => this.handleEditBuyer(buyer)}>
                            Edit
                          </a>
                          <Divider type='vertical' />
                          <a data={buyer._id} onClick={this.handleDeleteBuyer}>
                            Delete
                          </a>
                        </span>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>*/}
          </Tabs>
        </Spin>
        {this.state.addUserVisible ? (
          <Modal
            title="User"
            visible={this.state.addUserVisible}
            onOk={this.handleAddUser}
            onCancel={e => this.setState({ addUserVisible: false })}
            okText={this.state.editUser ? "Save" : "Add User"}
            cancelText="Cancel"
          >
            <UserForm
              managers={this.props.managers}
              editUser={this.state.editUser}
              wrappedComponentRef={form => (this.userForm = form)}
            />
          </Modal>
        ) : null}
        <Modal
          title="Add Manager"
          visible={this.state.addManagerVisible}
          onOk={this.handleAddManager}
          onCancel={e => this.setState({ addManagerVisible: false })}
          okText={this.state.editManager ? "Save" : "Add Manager"}
          cancelText="Cancel"
        >
          <ManagerForm
            editManager={this.state.editManager}
            wrappedComponentRef={form => (this.managerForm = form)}
          />
        </Modal>
        <Modal
          title="Add Buyer"
          visible={this.state.addBuyerVisible}
          onOk={this.handleAddBuyer}
          onCancel={e => this.setState({ addBuyerVisible: false })}
          okText={this.state.editBuyer ? "Save" : "Add Buyer"}
          cancelText="Cancel"
        >
          <BuyerForm
            editBuyer={this.state.editBuyer}
            wrappedComponentRef={form => (this.buyerForm = form)}
          />
        </Modal>
        <SuccessModal
          open={this.state.successModal}
          onClose={this.handleCloseSuccessModal}
          center
        >
          <img src={LogoModal} alt="Logo" style={{ width: "65%" }} />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
            }}
          >
            Allow Notification Sent
          </h3>{" "}
          <br />
          <Button
            onClick={this.handleCloseSuccessModal}
            style={{
              margin: "auto",
              height: "2.9em",

              fontSize: "1rem",
              fontWeight: 500,
              borderRadius: "5em",
              backgroundColor: "#F79489",
              color: "white",
              border: "none",
              marginBottom: "1em",
              paddingLeft: "3em",
              paddingRight: "3em",
              marginRight: "0em",
              textShadow: "none",
            }}
            type="primary"
          >
            Close
          </Button>{" "}
        </SuccessModal>
      </div>
    );
  }
}

const WrappedUsers = Form.create()(Users);

export default WrappedUsers;
