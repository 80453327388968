import React from "react";
import styled from "styled-components";
import { SettingFilled } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import ArrowBottomIcon from "../../../static/arrow-bottom-icon.svg";
import LinnetteBack from "../../../static/linnette-back.png";
import { withRouter, Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { BiStar } from "react-icons/bi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Menu,
  Select,
  Row,
  Col,
  Spin,
  Input,
  List,
  Switch,
  Badge,
  Rate,
  Modal,
  Button,
} from "antd";
import { Layout } from "antd";
import Resizer from "react-image-file-resizer";
import { ButtonSquare } from "src/components/elements/Button";
import Notify from "src/components/meta/Notification";
const { Content, Sider } = Layout;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const settingsTestimonials = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const FormItem = Form.Item;
const Option = Select.Option;
const Account = props => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Button
            className="button-hover-1"
            style={{
              borderRadius: 50,
              fontWeight: 600,
              height: "3.5em",
              width: "14.5em",
              fontSize: "16px",
              marginLeft: window.isPhone ? "" : "-2em",
              backgroundColor: "#F79489",
              color: "white",
              border: "none",
              marginTop: "2em",
            }}
            type="primary"
            htmlType="submit"
          >
            Start Your Journey
            <img
              style={{
                marginLeft: "1.5em",
                width: "2.5em",
                transform: "rotate(-90deg)",
              }}
              src={ArrowBottomIcon}
            />
          </Button>
        </Col>
      </Row>
    </div>
  );
};
const Password = props => {
  return (
    <Form onSubmit={props.handlePasswordChange}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator("newPass", {
              rules: [
                { required: true, message: "New password required" },
                { validator: props.validateToNextPassword },
              ],
            })(
              <Input.Password
                onBlur={props.handleConfirmBlur}
                type="password"
                style={{ borderColor: "#BDB2B2", height: 45 }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Confirm New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator("confirmPass", {
              rules: [
                { required: true, message: "Confirm new password" },
                { validator: props.compareToFirstPassword },
              ],
            })(
              <Input.Password
                type="password"
                style={{ borderColor: "#BDB2B2", height: 45 }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <FormItem>
          <ButtonSquare
            style={{ height: 45 }}
            className="submit-button"
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </ButtonSquare>
        </FormItem>
      </Row>
    </Form>
  );
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 2 },
  },
};
const Notification = props => {
  return (
    <Form {...formItemLayout} onSubmit={props.handleNotificationSave}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me when my car gets a bid
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me when GNFSA reviews my stock
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me of GNFSA updates
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};
class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: this.props.user.image,
      activeView: "account",
      visible: true,
    };
  }
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newPass")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirmPass"], { force: true });
    }
    callback();
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleAccountInfoSave = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.image = this.state.image;
        this.props
          .saveAccountInfo(values)
          .then(() => {
            this.setState({ loading: false });
            Notify(
              "success",
              "Success",
              "Successfully saved account information"
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", window.i18n.translate("Notify3"), e);
          });
      }
    });
  };
  handlePasswordChange = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values)
          .then(() => {
            this.setState({ loading: false });
            Notify("success", "Success", "Successfully changed password");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", window.i18n.translate("Notify3"), e);
          });
      }
    });
  };
  handleProfileImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        image: imageUrl,
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 200, 200, "PNG", 100, 0, uri => {
      callback(uri);
    });
  };
  removeImage = e => {
    this.setState({
      image: undefined,
    });
  };
  render() {
    return (
      <div style={{ maxWidth: "90em", margin: "auto" }}>
        <img
          style={{
            width: "100vw",
            height: "95vh",
            objectFit: "cover",
            margin: "auto",
            opacity: 0.4,
            position: "absolute",
            left: 0,
          }}
          src={LinnetteBack}
        />
        <Row>
          <Col span={24} style={{ marginTop: "6em", textAlign: "center" }}>
            <Link to="/user/stock">
              <Button
                className="button-hover-1"
                style={{
                  borderRadius: 10,
                  fontWeight: 600,
                  height: "10.5em",
                  width: "14.5em",
                  fontSize: "16px",
                  marginLeft: window.isPhone ? "" : "-2em",
                  backgroundColor: "#F79489",
                  color: "white",
                  border: "none",
                  marginTop: "2em",
                }}
                type="primary"
                htmlType="submit"
              >
                Start Learning
                <BiStar
                  style={{
                    marginLeft: "0.5em",
                    marginBottom: "-0.3em",
                    fontSize: "40px",
                  }}
                />
              </Button>
            </Link>
            <Link to="/user/users">
              <Button
                className="button-hover-1"
                style={{
                  borderRadius: 10,
                  fontWeight: 600,
                  height: "10.5em",
                  width: "14.5em",
                  fontSize: "16px",
                  marginLeft: "2em",
                  backgroundColor: "#F79489",
                  color: "white",
                  border: "none",
                  marginTop: "2em",
                }}
                type="primary"
                htmlType="submit"
              >
                View Profile
                <CgProfile
                  style={{
                    marginLeft: "0.5em",
                    marginBottom: "-0.3em",
                    fontSize: "40px",
                  }}
                />
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{}}>
            <Slider {...settingsTestimonials}>
              <div class="carousel-item active">
                <div
                  style={{
                    textAlign: "center",
                    padding: window.isPhone ? "1em" : "3em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <h2>Thank you so much</h2>

                  <p>
                    I have learned tonight...SO appreciate these videos..! They truly help.<br />
                    Thank you. 😍
                  </p>

                  <h4>Charm Van Heerden</h4>
                  <Rate disabled defaultValue={5} />
                </div>

              </div>
              <div class="carousel-item active">
                <div
                  style={{
                    textAlign: "center",
                    padding: window.isPhone ? "1em" : "3em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <h2>I am proud of you</h2>

                  <p>
                    Hello Linnette,
                    <br /> <br />
                    Do you know that you are unique? <br />
                    As a network marketer, the work you do inspires me. And I am
                    proud to be associated with you. <br />
                    You too should be - of the tremendous work you do and lives
                    you impact!
                  </p>

                  <h4>Eric Worre</h4>
                  <Rate disabled defaultValue={5} />
                </div>
              </div>
              <div class="carousel-item active">
                <div
                  style={{
                    textAlign: "center",
                    padding: window.isPhone ? "1em" : "3em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <h2>
                    {" "}
                    Linnette, in my opinion the leading leader in Duepoint
                  </h2>
                  {/* <img src={DuePoint} style={{ marginTop: "2em" }} /> */}
                  <p>
                    In 2021, I decide to make a change and joined DuePoint.
                    First, I thoroughly investigated DuePoint’s business
                    practice and was satisfied that it is an excellent, legal
                    business that offers people who are willing to work hard,
                    the opportunity to build his/her own business and the
                    ability to create a good sustainable income without a huge
                    capital layout. What impressed me most was the DuePoint
                    tools that allow new Wealth Engineers to independently build
                    their own businesses.
                  </p>
                  {/* <p>
                  I met Linnette Badenhorst about 5 years ago and she introduced me to Duepoint but because I was already involved in another network in a senior capacity for about 22 years, I could not join Duepoint at that time. <br />

                  In 2021, during the Covid 19 period, I decide to make a change and joined DuePoint. First, I thoroughly investigated DuePoint’s business practice and was satisfied that it is an excellent, legal business that offers people who are willing to work hard, the opportunity to build his/her own business and the ability to create a good sustainable income without a huge capital layout.
                  What impressed me most was the DuePoint tools that allow new Wealth Engineers to independently build their own businesses.
                  Another plus for me was the support DuePoint’s customer service division provides to assist Wealth Engineers with advice and guidance. <br />

                  My husband saw Linnette success story on Facebook and joined under Linnette, in my opinion the leading leader in Duepoint. Linnette, with her extensive knowledge of network marketing and the operation of Duepoint, provides me with the necessary guidance that enables me to make a success of my own business. I can turn to her for help seven days a week.<br />

                  I am convinced that in South Africa, with all its economic problems and challenges, DuePoint, with its responsible and highly skilled management and staff, offers thousands of people a way to independently develop their own businesses.<br />

                  Linnette who has been involved with Duepoint from the beginning has the knowledge and skill to provide new Wealth Engineers with the necessary assistance and guidance to enable them to make a success of their own businesses.<br />
                </p> */}
                  <h4>ERINA LODEWYCKX</h4>
                  <Rate disabled defaultValue={5} />
                </div>
              </div>
              <div class="carousel-item active">
                <div
                  style={{
                    textAlign: "center",
                    padding: window.isPhone ? "1em" : "3em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  {/* <h2> Linnette, in my opinion the leading leader in Duepoint</h2> */}
                  {/* <img src={DuePoint} style={{ marginTop: "2em" }} /> */}
                  <p>
                    Thank you for going the extra mile to teach us how to use
                    social media correctly, Linnette.
                  </p>
                  <h4 style={{ textTransform: "uppercase" }}>
                    Carin Van Rooyen Pienaar
                  </h4>
                  <Rate disabled defaultValue={5} />
                </div>
              </div>
              <div class="carousel-item active">
                <div
                  style={{
                    textAlign: "center",
                    padding: window.isPhone ? "1em" : "3em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <h2> Thank you Linnette</h2>
                  {/* <img src={DuePoint} style={{ marginTop: "2em" }} /> */}
                  <p>You are truly an inspiration. ❤️</p>

                  <h4 style={{ textTransform: "uppercase" }}>Dawn Grant</h4>
                  <Rate disabled defaultValue={5} />
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
        <Modal
          title="Welcome to Team Eagle Training"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              style={{ backgroundColor: "rgb(247, 148, 137)", color: "white" }}
              key="back"
              onClick={this.handleCancel}
            >
              Continue
            </Button>,
          ]}
        >
          <p>
            Congratulations on your decision to join DuePoint, the best and most
            powerful income generating system in the world!
            <br />
            <br />
            I believe that this is one of the most important decisions of your
            life, as it can truly change your entire financial future, as it did
            for mine.
            <br />
            It took us out of the 26-year unemployment rut and offered us the
            ability to employ ourselves and take control of our income, with a
            FREE car and a FREE annual 5-star luxury holiday on top of it.
            <br />
            <br /> I trust that this training program, in conjunction with the
            training on your DuePoint app, can help you achieve the same level
            of success.
            <br />
            <br /> Remember:
            <br /> “If it's going to be, it's up to ME”.
            <br />
            <br />
            Regards. <br />
            Linnette & Frikkie Badenhorst
          </p>
        </Modal>
      </div>
    );
  }
}
const WrappedAccountSettings = Form.create()(AccountSettings);
export default WrappedAccountSettings;
