import React, { PureComponent } from 'react';
import { Layout } from 'antd';
export class ResFooter extends PureComponent {
  render() {
    return (
      <Layout.Footer
        style={{
          zIndex: 10,
          background: '#F79489',
          height: 50,
          textAlign: 'center',
          color: 'white'
        }}
      >
        2020 Copyright GNFSA
      </Layout.Footer>
    );
  }
}

export default ResFooter;
