import React from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Button,
  PageHeader,
  Badge,
  Table,
  Input,
  Modal,
  Select,
  Spin,
  Divider,
  Card,
  Typography,
  Tabs,
  AutoComplete,
  Upload,
  Checkbox,
  Space,
  InputNumber,
  Switch,
  Tooltip
} from 'antd';
import {
  CloseCircleFilled,
  CheckOutlined,
  CloseOutlined,
  BellOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  DeleteFilled,
  PlusOutlined
} from '@ant-design/icons';
import { ButtonSquare } from 'src/components/elements/Button';
import dropImage from 'src/static/icons/carImageUpload/placeholder.png';
import Resizer from 'react-image-file-resizer';
import { Form } from '@ant-design/compatible';
import Notify from 'src/components/meta/Notification';
import Highlighter from 'react-highlight-words';

const { Title } = Typography;
const { Column } = Table;
const FormItem = Form.Item;

const { TabPane } = Tabs;

const { Option } = Select;
const Cards = styled(Card)`
  .ant-card-body {
    display: none !important;
  }
`;
const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #8898aa !important;
    background: #f1f1f1 !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    font-weight: 500;
    color: #525f7f;
    font-size: 15px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    content: '';
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 47px !important;
  border-right-width: 0px !important;
    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border-bottom: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;
    border-right-width: 0px !important;
    box-shadow:none !important;
}
  :active{
    border-color: red !important;
    border-right-width: 0px !important;
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    border-right-width: 0px !important;
     box-shadow:none !important;
   
  }
  }
`;
const StyledInputNumber = styled(InputNumber)`
  font-weight: 500 !important;
  color: #525f7f !important;
  font-size: 15px !important;
  cursor: not-allowed;
  .ant-input-number-disabled .ant-input-number-input {
    font-weight: 500 !important;
    color: #525f7f !important;
    font-size: 15px !important;
    cursor: not-allowed;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid lightgrey;
  border-radius: 0em !important;
  height: 47px !important;
  box-shadow: none !important;
  :hover {
    border-color: red !important;

    box-shadow: none !important;
  }
  :active {
    border-color: red !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: red !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
function callback(key) {
  console.log(key);
}
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class AddPexunitForm extends React.Component {
  state = {
    manualInput: this.props.editPexunit
      ? this.props.editPexunit.manualInput
      : false,
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
    pexunitNumbers: [],
    years: [],
    makes: [],
    variants: [],
    models: [],
    mileages: [],
    retailIncs: [],
    intColours: [],
    extColours: [],
    capacityCCs: [],
    bodyTypes: [],
    engineSizes: [],
    unladenWeights: [],
    vehicleDrives: [],
    axles: [],
    doors: [],
    cylinders: [],
    passengers: [],
    intColour: undefined,
    extColour: undefined,
    passenger: undefined,
    retailPriceIncl: undefined,
    cylinder: undefined,
    unladenWeight: undefined,
    axle: undefined,
    vehicleDrive: undefined,
    engineSize: undefined,
    bodyType: undefined,
    capacityCC: undefined,
    pexunitNumber: undefined,
    modelYear: undefined,
    pageName: undefined,
    make: undefined,
    mileage: undefined,
    series: undefined,
    homeImage: undefined,
    homeImage2: undefined,
    homeImage3: undefined,
    branchImageMegaGroup: undefined,
    branchImageKia: undefined,
    branchImageMahindra: undefined,
    branchImageChery: undefined,
    branchImageGWM: undefined,
    branchImageHaval: undefined,
    frontLeftImage: undefined,
    frontImage: undefined,
    frontRightImage: undefined,
    rightSideImage: undefined,
    wheelsImage: undefined,
    backRightImage: undefined,
    backImage: undefined,
    backLeftImage: undefined,
    leftSideImage: undefined,
    bootImage: undefined,
    frontSeatImage: undefined,
    backSeatImage: undefined,
    dashPassengerImage: undefined,
    dashDriverImage: undefined,
    dashDriverSideImage: undefined,
    radioImage: undefined,
    radioCloseImage: undefined,
    gearsImage: undefined,
    steeringImage: undefined,
    spareKeyImage: undefined
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    });
  };
  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput
    });
  };
  selectPexunitNumber = pexunitNumber => {
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findyears?pexunitNumber=' +
      pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        years: result.years,
        modelYear: undefined,
        pexunitNumber: pexunitNumber
      });
    });
  };
  selectYear = (
    modelYear,
    make,
    series,
    mileage,
    capacityCC,
    bodyType,
    engineSize,
    vehicleDrive,
    unladenWeight,
    axle,
    door,
    cylinder,
    intColour,
    extColour,
    retailPriceIncl
  ) => {
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findmakes?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        makes: result.makes,

        modelYear: modelYear,
        make: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findmodels?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({ models: result.models, make: make, series: undefined });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findvariants?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        variants: result.variants,
        series: series,
        modelDesc: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findmileages?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        mileages: result.mileages,

        mileage: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findcapacityccs?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        capacityCCs: result.capacityCCs,
        mileage: mileage,
        capacityCC: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findvehicledrives?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        vehicleDrives: result.vehicleDrives,
        capacityCC: capacityCC,
        vehicleDrive: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findvehicledrives?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        engineSizes: result.engineSizes,
        bodyType: bodyType,
        engineSize: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findbodytypes?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        bodyTypes: result.bodyTypes,
        bodyType: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findvehicledrives?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        vehicleDrive: result.vehicleDrive,
        engineSize: engineSize,
        vehicleDrive: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findunladenweights?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        unladenWeights: result.unladenWeights,
        vehicleDrive: vehicleDrive,
        unladenWeight: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findaxles?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        axles: result.axles,
        unladenWeight: unladenWeight,
        axle: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/finddoors?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        doors: result.doors,
        axle: axle,
        door: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findcylinders?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        cylinders: result.cylinders,
        door: door,
        cylinder: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findpassengers?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        passengers: result.passengers,
        cylinder: cylinder,
        passenger: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findretailincs?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        retailIncs: result.retailIncs,

        retailPriceIncl: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findextcolours?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        extColours: result.extColours,

        extColour: undefined
      });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER +
      'brandxelerator/api/evolve/findintcolours?pexunitNumber=' +
      this.state.pexunitNumber,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving makes'
        });
      const result = await res.json();
      this.setState({
        intColours: result.intColours,

        intColour: undefined
      });
    });
  };
  //selectMake = (make) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findmodels?pexunitNumber=" +
  //      this.state.pexunitNumber,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({ models: result.models, make: make, series: undefined });
  //  });
  //};
  //selectModel = (series) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findvariants?pexunitNumber=" +
  //      this.state.pexunitNumber,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      variants: result.variants,
  //      series: series,
  //      modelDesc: undefined,
  //    });
  //  });
  //};
  //selectVariant = (modelDesc) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findmileages?pexunitNumber=" +
  //      this.state.pexunitNumber,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      mileages: result.mileages,
  //      modelDesc: modelDesc,
  //      mileage: undefined,
  //    });
  //  });
  //};
  //selectMileage = (mileage) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findcapacityccs?pexunitNumber=" +
  //      this.state.pexunitNumber,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      capacityCCs: result.capacityCCs,
  //      mileage: mileage,
  //      capacityCC: undefined,
  //    });
  //  });
  //};
  //selectCapacityCC = (capacityCC) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findvehicledrives?pexunitNumber=" +
  //      this.state.pexunitNumber,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      vehicleDrives: result.vehicleDrives,
  //      capacityCC: capacityCC,
  //      vehicleDrive: undefined,
  //    });
  //  });
  //};
  //selectBodyType = (bodyType) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findvehicledrives?pexunitNumber=" +
  //      this.state.pexunitNumber,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      engineSizes: result.engineSizes,
  //      bodyType: bodyType,
  //      engineSize: undefined,
  //    });
  //  });
  //};
  //selectEngineSize = (engineSize) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findvehicledrives?pexunitNumber=" +
  //      this.state.pexunitNumber,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      vehicleDrive: result.vehicleDrive,
  //      engineSize: engineSize,
  //      vehicleDrive: undefined,
  //    });
  //  });
  //};
  //selectVehicleDrive = (vehicleDrive) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findunladenweights?vehicledrive=" +
  //      vehicleDrive +
  //      "&year=" +
  //      this.state.modelYear,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      unladenWeights: result.unladenWeights,
  //      vehicleDrive: vehicleDrive,
  //      unladenWeight: undefined,
  //    });
  //  });
  //};
  //selectUnladenWeight = (unladenWeight) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findaxles?unladenweight=" +
  //      unladenWeight +
  //      "&year=" +
  //      this.state.modelYear,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      axles: result.axles,
  //      unladenWeight: unladenWeight,
  //      axle: undefined,
  //    });
  //  });
  //};
  //selectAxle = (axle) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/finddoors?axle=" +
  //      axle +
  //      "&year=" +
  //      this.state.modelYear,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      doors: result.doors,
  //      axle: axle,
  //      door: undefined,
  //    });
  //  });
  //};
  //selectDoor = (door) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findcylinders?door)=" +
  //      door +
  //      "&year=" +
  //      this.state.modelYear,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      cylinders: result.cylinders,
  //      door: door,
  //      cylinder: undefined,
  //    });
  //  });
  //};
  //selectCylinder = (cylinder) => {
  //  fetch(
  //    process.env.REACT_APP_GLOBAL_SERVER +
  //      "brandxelerator/api/evolve/findpassengers?cylinder)=" +
  //      cylinder +
  //      "&year=" +
  //      this.state.modelYear,
  //    {
  //      method: "GET",
  //      mode: "cors",
  //      headers: {
  //        "Content-Type": "application/json; charset=utf-8",
  //      },
  //    }
  //  ).then(async (res) => {
  //    if (!res.ok)
  //      Notify.error({
  //        message: "Error retrieving makes",
  //      });
  //    const result = await res.json();
  //    this.setState({
  //      passengers: result.passengers,
  //      cylinder: cylinder,
  //      passenger: undefined,
  //    });
  //  });
  //};
  //selectPassenger = (passenger) => {
  //  this.setState({
  //    passenger: passenger,
  //  });
  //};
  componentWillMount = () => {
    if (this.props.editPexunit) {
      this.setState({
        homeImage: this.props.editPexunit.homeImage,
        homeImage2: this.props.editPexunit.homeImage2,
        homeImage3: this.props.editPexunit.homeImage3,
        branchImageMegaGroup: this.props.editPexunit.branchImageMegaGroup,
        branchImageKia: this.props.editPexunit.branchImageKia,
        branchImageMahindra: this.props.editPexunit.branchImageMahindra,
        branchImageChery: this.props.editPexunit.branchImageChery,
        branchImageGWM: this.props.editPexunit.branchImageGWM,
        branchImageHaval: this.props.editPexunit.branchImageHaval
      });
    }
  };
  handleHomeImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        homeImage: imageUrl
      });
    });
  };
  handleHomeImage2 = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        homeImage2: imageUrl
      });
    });
  };
  handleHomeImage3 = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        homeImage3: imageUrl
      });
    });
  };
  handleBranchImageMegaGroup = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        branchImageMegaGroup: imageUrl
      });
    });
  };
  handleBranchImageKia = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        branchImageKia: imageUrl
      });
    });
  };

  handleBranchImageMahindra = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        branchImageMahindra: imageUrl
      });
    });
  };
  handleBranchImageChery = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        branchImageChery: imageUrl
      });
    });
  };
  handleBranchImageGWM = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        branchImageGWM: imageUrl
      });
    });
  };
  handleBranchImageHaval = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        branchImageHaval: imageUrl
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify('error', window.i18n.translate('ImageSmaller'));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 800, 800, 'JPG', 100, 0, uri => {
      callback(uri);
    });
  };

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Form layout="vertical">
        <h1
          style={{
            marginTop: '0em',
            marginBottom: '1.5em',
            fontSize: '17px',
            fontWeight: 700
          }}
        >
          <FormItem label={<span>Home Page Controller</span>}>
            {this.props.form.getFieldDecorator('pageName', {
              rules: [
                {
                  required: false,
                  message: 'Home Title is required'
                }
              ],

              initialValue: 'Home Page'
            })(<StyledInput disabled />)}
          </FormItem>{' '}
        </h1>
        <Row type="flex" justify="space-between" align="middle">
          <Col span={6} style={{ padding: '0.5em' }}>
            <FormItem label={<span>Home Title 1</span>}>
              {this.props.form.getFieldDecorator('homeTitle', {
                rules: [
                  {
                    required: false,
                    message: 'Home Title is required'
                  }
                ],
                initialValue: this.props.editPexunit
                  ? this.props.editPexunit.homeTitle
                  : ''
              })(<StyledInput />)}
            </FormItem>{' '}
          </Col>{' '}
          <Col span={6} style={{ padding: '0.5em' }}>
            <FormItem label={<span>Home Title 2</span>}>
              {this.props.form.getFieldDecorator('homeTitle1', {
                rules: [
                  {
                    required: false,
                    message: 'Home Title is required'
                  }
                ],
                initialValue: this.props.editPexunit
                  ? this.props.editPexunit.homeTitle1
                  : ''
              })(<StyledInput />)}
            </FormItem>{' '}
          </Col>
          <Col span={6} style={{ padding: '0.5em' }}>
            <FormItem label={<span>Home Title 3</span>}>
              {this.props.form.getFieldDecorator('homeTitle2', {
                rules: [
                  {
                    required: false,
                    message: 'Home Title is required'
                  }
                ],
                initialValue: this.props.editPexunit
                  ? this.props.editPexunit.homeTitle2
                  : ''
              })(<StyledInput />)}
            </FormItem>{' '}
          </Col>
        </Row>{' '}
        <Row type="flex" justify="space-between" align="middle">
          <Col span={8} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid #F79489',
                marginBottom: 10
              }}
              extra={
                this.state.homeImage ? (
                  <div onClick={e => this.setState({ homeImage: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: '#F79489' }}>Home Image 1</span>}
              cover={
                this.state.homeImage ? (
                  <img
                    alt="example"
                    src={
                      this.state.homeImage.search('data') > -1
                        ? this.state.homeImage
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'brandxelerator/api/images/' +
                        this.state.homeImage
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="homeImage"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleHomeImage}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <ButtonSquare>Upload Image</ButtonSquare>
                </Upload>
              ]}
            />
          </Col>
          <Col span={8} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid #F79489',
                marginBottom: 10
              }}
              extra={
                this.state.homeImage2 ? (
                  <div onClick={e => this.setState({ homeImage2: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: '#F79489' }}>Home Image 2</span>}
              cover={
                this.state.homeImage2 ? (
                  <img
                    alt="example"
                    src={
                      this.state.homeImage2.search('data') > -1
                        ? this.state.homeImage2
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'brandxelerator/api/images/' +
                        this.state.homeImage2
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="homeImage2"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleHomeImage2}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <ButtonSquare>Upload Image</ButtonSquare>
                </Upload>
              ]}
            />
          </Col>
          <Col span={8} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid #F79489',
                marginBottom: 10
              }}
              extra={
                this.state.homeImage3 ? (
                  <div onClick={e => this.setState({ homeImage3: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: '#F79489' }}>Home Image 3</span>}
              cover={
                this.state.homeImage3 ? (
                  <img
                    alt="example"
                    src={
                      this.state.homeImage3.search('data') > -1
                        ? this.state.homeImage3
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'brandxelerator/api/images/' +
                        this.state.homeImage3
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="homeImage3"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleHomeImage3}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <ButtonSquare>Upload Image</ButtonSquare>
                </Upload>
              ]}
            />
          </Col>
        </Row>{' '}
        <Row type="flex" justify="space-between" align="middle">
          <h1
            style={{
              marginTop: '0em',
              marginBottom: '1.5em',
              fontSize: '17px',
              fontWeight: 700
            }}
          >
            Branch Images
          </h1>
        </Row>
        <Row type="flex" justify="space-between" align="middle">
          <Col span={8} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid #F79489',
                marginBottom: 10
              }}
              extra={
                this.state.branchImageMegaGroup ? (
                  <div
                    onClick={e => this.setState({ branchImageMegaGroup: null })}
                  >
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={
                <span style={{ color: '#F79489' }}>Mega Theron Image</span>
              }
              cover={
                this.state.branchImageMegaGroup ? (
                  <img
                    alt="example"
                    src={
                      this.state.branchImageMegaGroup.search('data') > -1
                        ? this.state.branchImageMegaGroup
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'brandxelerator/api/images/' +
                        this.state.branchImageMegaGroup
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="branchImageMegaGroup"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleBranchImageMegaGroup}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <ButtonSquare>Upload Image</ButtonSquare>
                </Upload>
              ]}
            />
          </Col>
          <Col span={8} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid #F79489',
                marginBottom: 10
              }}
              extra={
                this.state.branchImageKia ? (
                  <div onClick={e => this.setState({ branchImageKia: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: '#F79489' }}>Kia Image</span>}
              cover={
                this.state.branchImageKia ? (
                  <img
                    alt="example"
                    src={
                      this.state.branchImageKia.search('data') > -1
                        ? this.state.branchImageKia
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'brandxelerator/api/images/' +
                        this.state.branchImageKia
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="branchImageKia"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleBranchImageKia}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <ButtonSquare>Upload Image</ButtonSquare>
                </Upload>
              ]}
            />
          </Col>
          <Col span={8} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid #F79489',
                marginBottom: 10
              }}
              extra={
                this.state.branchImageMahindra ? (
                  <div
                    onClick={e => this.setState({ branchImageMahindra: null })}
                  >
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: '#F79489' }}>Mahindra Image</span>}
              cover={
                this.state.branchImageMahindra ? (
                  <img
                    alt="example"
                    src={
                      this.state.branchImageMahindra.search('data') > -1
                        ? this.state.branchImageMahindra
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'brandxelerator/api/images/' +
                        this.state.branchImageMahindra
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="branchImageMahindra "
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleBranchImageMahindra}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <ButtonSquare>Upload Image</ButtonSquare>
                </Upload>
              ]}
            />
          </Col>
          <Col span={8} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid #F79489',
                marginBottom: 10
              }}
              extra={
                this.state.branchImageChery ? (
                  <div onClick={e => this.setState({ branchImageChery: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: '#F79489' }}>Chery Image</span>}
              cover={
                this.state.branchImageChery ? (
                  <img
                    alt="example"
                    src={
                      this.state.branchImageChery.search('data') > -1
                        ? this.state.branchImageChery
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'brandxelerator/api/images/' +
                        this.state.branchImageChery
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="branchImageChery "
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleBranchImageChery}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <ButtonSquare>Upload Image</ButtonSquare>
                </Upload>
              ]}
            />
          </Col>
          <Col span={8} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid #F79489',
                marginBottom: 10
              }}
              extra={
                this.state.branchImageGWM ? (
                  <div onClick={e => this.setState({ branchImageGWM: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: '#F79489' }}>GWM Image</span>}
              cover={
                this.state.branchImageGWM ? (
                  <img
                    alt="example"
                    src={
                      this.state.branchImageGWM.search('data') > -1
                        ? this.state.branchImageGWM
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'brandxelerator/api/images/' +
                        this.state.branchImageGWM
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="branchImageGWM "
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleBranchImageGWM}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <ButtonSquare>Upload Image</ButtonSquare>
                </Upload>
              ]}
            />
          </Col>
          <Col span={8} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid #F79489',
                marginBottom: 10
              }}
              extra={
                this.state.branchImageHaval ? (
                  <div onClick={e => this.setState({ branchImageHaval: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: '#F79489' }}>Haval Image</span>}
              cover={
                this.state.branchImageHaval ? (
                  <img
                    alt="example"
                    src={
                      this.state.branchImageHaval.search('data') > -1
                        ? this.state.branchImageHaval
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'brandxelerator/api/images/' +
                        this.state.branchImageHaval
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="branchImageHaval "
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleBranchImageHaval}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <ButtonSquare>Upload Image</ButtonSquare>
                </Upload>
              ]}
            />
          </Col>
        </Row>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>{' '}
        {/*<Row type='flex' justify='space-around' align='middle'>
          <Button type='primary' onClick={this.props.onOk}>
            {this.props.okText}
          </Button>
          <Button type='primary' onClick={this.props.onCancel}>
            Cancel
          </Button>
        </Row>*/}
      </Form>
    );
  }
}
const PexunitForm = Form.create()(AddPexunitForm);

class Pexunit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: '',
      editPexunit: undefined,
      size: 'large',
      flag: false,
      currentTab: 'All'
    };
  }
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllPexunit()
      .then(() => {
        let itemCodes = [];
        this.props.pexunit.forEach(pexunit => {
          if (pexunit.itemCode) {
            itemCodes.push(
              'Part: ' + pexunit.itemCode + ' - Location: ' + pexunit.location
            );
          }
        });
        this.setState({ loading: false, itemCodes: itemCodes });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllPexunit()
          .then(() => {
            let itemCodes = [];
            this.props.pexunit.forEach(pexunit => {
              if (pexunit.itemCode) {
                itemCodes.push(
                  'Part: ' +
                  pexunit.itemCode +
                  ' - Location: ' +
                  pexunit.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    };
    window.pubnub.addListener(this.updateSignal);
    window.addEventListener('resize', this.resizeEvent);
    if (window.cordova) {
      document.addEventListener('backbutton', this.handleBack);
    }
  };
  handleBack = () => {
    if (this.state.previewVisible) {
      this.setState({ previewVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener('backbutton', this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  vehiclesForm;
  onAddPexunit = e => {
    this.setState({ previewVisible: true, editPexunit: undefined });
  };

  handleAddPexunit = e => {
    e.preventDefault();
    this.vehiclesForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.homeImage = this.vehiclesForm.state.homeImage;
        values.homeImage2 = this.vehiclesForm.state.homeImage2;
        values.homeImage3 = this.vehiclesForm.state.homeImage3;
        values.branchImageMegaGroup = this.vehiclesForm.state.branchImageMegaGroup;
        values.branchImageKia = this.vehiclesForm.state.branchImageKia;
        values.branchImageMahindra = this.vehiclesForm.state.branchImageMahindra;
        values.branchImageChery = this.vehiclesForm.state.branchImageChery;
        values.branchImageGWM = this.vehiclesForm.state.branchImageGWM;
        values.branchImageHaval = this.vehiclesForm.state.branchImageHaval;
        if (this.state.editPexunit) {
          values._id = this.state.editPexunit._id;
        }
        this.props
          .addPexunit(values)
          .then(() => {
            this.props
              .findAllPexunit()
              .then(() => {
                if (this.state.editPexunit) {
                  Notify('success', 'Successfully saved vehicle');
                } else {
                  Notify('success', 'Successfully added vehicle');
                }
                this.vehiclesForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  previewVisible: false,
                  editPexunit: undefined,
                  currentTab: 'All'
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })

          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };

  handleEditPexunit = e => {
    this.setState({ previewVisible: true, editPexunit: e });
  };

  handleDeletePexunit = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are you sure you want to delete this Event?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deletePexunit(id)
          .then(() => {
            that.props
              .findAllPexunit()
              .then(() => {
                that.setState({ loading: false, currentTab: 'All' });
                // that.props.find140mm();
                // that.props.find171mm();
                Notify('success', 'Successfully deleted vehicle');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() { }
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedInfo: sorter
    });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#F79489' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  renderCondition = (condition, color) => {
    return (
      <p style={{ margin: 0 }}>
        <StyledBadge color={color} style={{}} />
        {condition}
      </p>
    );
  };

  changeTypeTab = key => {
    this.setState({ currentTab: key });
  };

  render() {
    let descriptions = this.props.pexunit.map(vehicle => {
      return { value: vehicle.description };
    });
    const { condition } = this.state;
    const { pexunit } = this.props;
    let vehicles;
    if (this.state.currentTab === 'All') {
      vehicles = pexunit;
    } else {
      vehicles = pexunit.filter(
        vehicle => vehicle.condition === this.state.currentTab
      );
    }
    const dataSource = [
      {
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street'
      },
      {
        key: '2',
        name: 'John',
        age: 42,
        address: '10 Downing Street'
      }
    ];

    const columns = [
      {
        title: 'Website Page',
        dataIndex: 'pageName',
        key: 'pageName'
      },
      //{
      //  title: "Make",
      //  dataIndex: "make",
      //  key: "make",
      //},
      //{
      //  title: "Model",
      //  dataIndex: "series",
      //  key: "series",
      //},
      //{
      //  title: "Derivative",
      //  dataIndex: "modelDesc",
      //  key: "modelDesc",
      //},
      //{
      //  title: "Condition",
      //  dataIndex: "condition",
      //  //key: "condition",
      //  render: (condition) => {
      //    switch (condition) {
      //      case "New":
      //        return this.renderCondition("New", "#4BED5B");
      //      case "Used":
      //        return this.renderCondition("Used", "#EE4C4C");
      //      case "Demo":
      //        return this.renderCondition("Demo", "#FF8F28");
      //      default:
      //        return this.renderCondition("None", "lightgrey");
      //    }
      //  },
      //},
      //{
      //  title: "Price",
      //  dataIndex: "price",
      //  //key: "price",
      //  render: (price) => (
      //    <StyledInputNumber
      //      bordered={false}
      //      defaultValue={price}
      //      disabled
      //      formatter={(price) =>
      //        `R ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      //      }
      //      parser={(price) => price.replace(/\R\s?|(,*)/g, "")}
      //    />
      //  ),
      //},
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (text, pexunit) => (
          <span>
            <Button
              style={{
                backgroundColor: '#F79489',
                color: 'white',
                borderRadius: '3em'
              }}
              onClick={e => this.handleEditPexunit(pexunit)}
            >
              Edit
            </Button>
            <Divider type="vertical" />
            {/*<DeleteFilled
              style={{
                backgroundColor: "#CFD5E3",
                color: "white",
                padding: "0.5em",
                borderRadius: "3em",
              }}
              data={pexunit._id}
              onClick={this.handleDeletePexunit}
            />*/}
          </span>
        )
      }
    ];
    return (
      <div style={{ maxWidth: '90em', margin: 'auto' }}>
        <Spin spinning={this.state.loading}>
          {this.state.previewVisible ? (
            <Card
              bordered={false}
              extra={
                <Title
                  style={{
                    paddingRight: '75em',
                    fontSize: '20px',
                    fontWeight: 700,
                    whiteSpace: 'nowrap'
                  }}
                >
                  {this.state.editPexunit ? 'Edit Home Page' : 'Add Home Page'}
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: '15px',
                      whiteSpace: 'nowrap',
                      marginTop: '0.5em',
                      marginBottom: '0em'
                    }}
                  >
                    {this.state.editPexunit
                      ? 'Please click save to edit Home Page'
                      : 'Fill in all fields and click save to add a new Home Page'}
                  </p>
                </Title>
              }
              style={{
                margin: 'auto',
                marginTop: '3em',
                backgroundColor: 'white',
                borderRadius: '0.8em',
                boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.08)'
              }}
            >
              <div
                style={{
                  padding: '3em',
                  paddingTop: '1em',
                  paddingBottom: '4em',
                  margin: 'auto'
                }}
              >
                <PexunitForm
                  descriptions={descriptions}
                  editPexunit={this.state.editPexunit}
                  wrappedComponentRef={form => (this.vehiclesForm = form)}
                />
                <Tooltip placement="left" title="Save Event">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.handleAddPexunit}
                    size="large"
                    style={{
                      position: 'fixed',
                      bottom: '6.5em',
                      right: '1em',
                      fontSize: '14px',
                      width: '4em',
                      height: '4em',
                      //height: "3em",
                      marginLeft: '0.7em'
                    }}
                  >
                    <CheckOutlined />
                  </Button>
                </Tooltip>
                <Tooltip placement="left" title="Cancel">
                  <Button
                    shape="round"
                    onClick={e => this.setState({ previewVisible: false })}
                    style={{
                      fontSize: '14px',
                      width: '4em',
                      height: '4em',
                      position: 'fixed',
                      bottom: '2em',
                      right: '1em',
                      border: '1px solid #EEEFF0',
                      backgroundColor: 'lightgrey'
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </Tooltip>
              </div>
            </Card>
          ) : (
            <Row>
              <Col span={24}>
                <StyledTabs
                  style={{ marginTop: '1em' }}
                  defaultActiveKey="1"
                  onChange={this.changeTypeTab}
                  tabBarExtraContent={
                    {
                      //left: (
                      //  <Button
                      //    type='primary'
                      //    shape='round'
                      //    onClick={this.onAddPexunit}
                      //    icon={
                      //      <PlusCircleOutlined style={{ fontSize: "110%" }} />
                      //    }
                      //    size='large'
                      //    style={{
                      //      marginRight: "2.5em",
                      //      height: "3em",
                      //      width: "9em",
                      //    }}
                      //  >
                      //    Add Pexunit
                      //  </Button>
                      //),
                      //right: (
                      //  <h1 style={{ color: "#656565", fontSize: 22 }}>
                      //    Total Pexunit:
                      //    <span
                      //      style={{ color: "#F79489", fontSize: 25 }}
                      //    >
                      //      {this.props.pexunit.length}
                      //    </span>
                      //  </h1>
                      //),
                    }
                  }
                >
                  <TabPane tab="Home" key="All">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      columns={columns}
                      rowKey="_id"
                      size="middle"
                      pagination={
                        false
                        //defaultPageSize: 25,
                      }
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    />
                  </TabPane>
                  {/*<TabPane tab='New' key='New'>
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      columns={columns}
                      rowKey='_id'
                      size='middle'
                      pagination={
                        false
                        //defaultPageSize: 25,
                      }
                      style={{
                        minWidth: "15em",
                        marginTop: "0.5em",
                      }}
                    />
                  </TabPane>
                  <TabPane tab='Used' key='Used'>
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      columns={columns}
                      rowKey='_id'
                      size='middle'
                      pagination={
                        false
                        //defaultPageSize: 25,
                      }
                      style={{
                        minWidth: "15em",
                        marginTop: "0.5em",
                      }}
                    />
                  </TabPane>
                  <TabPane tab='Demo' key='Demo'>
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      columns={columns}
                      rowKey='_id'
                      size='middle'
                      pagination={
                        false
                        //defaultPageSize: 25,
                      }
                      style={{
                        minWidth: "15em",
                        marginTop: "0.5em",
                      }}
                    />
                  </TabPane>*/}
                </StyledTabs>
              </Col>
            </Row>
          )}
        </Spin>
      </div>
    );
  }
}

const WrappedPexunit = Form.create()(Pexunit);
export default WrappedPexunit;
