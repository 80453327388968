import React from 'react';
import { Layout } from 'antd';

import { connect } from 'react-redux';
import UserSidebar from './UserSidebar';
import ManagerSidebar from './ManagerSidebar';
import BuyerSidebar from './BuyerSidebar';
import AdminSidebar from './AdminSidebar';
import ResHeader from 'src/components/layout/ResHeader';
import ResFooter from 'src/components/layout/ResFooter';
import { getSidebarCollapsed, getUser } from 'src/redux/selectors';
import BackgroundImage from 'src/static/background.svg';
const { Content } = Layout;

if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const Template = props => {
  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <ResHeader />
        <Layout>
          {props.user.type === 'user' ? (
            <UserSidebar />
          ) : props.user.type === 'manager' ? (
            <ManagerSidebar />
          ) : props.user.type === 'buyer' ? (
            <BuyerSidebar />
          ) : (
            <AdminSidebar />
          )}
          <Layout
            style={{
              ...(props.background && {
                backgroundImage: `url(${BackgroundImage})`,
              }),
              marginLeft: window.isPhone ? 40 : 100,
              marginTop: 40,
              backgroundSize: "100%",
              backgroundPositionY: "100%",
              backgroundPosition: "bottom",
              backgroundRepeat: "no-repeat",
              transition: "all 0.2s",
              backgroundAttachment: "fixed"
            }}
          >
            <Content
              style={{
                transition: 'all 0.2s',
                margin: 10
              }}
            >
              {props.children}
            </Content>
            {/* <ResFooter /> */}
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    collapsed: getSidebarCollapsed(state),
    user: getUser(state) || {}
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Template);
